import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplication,
  updateSlug,
  createApplication,
  updateNoApplication,
  updateApplication,
  updateLoading,
  fetchOfferDetails,
  updateOfferDetails,
} from "components/ReFi/Application/redux/ApplicationSlice";
import {
  requestApplication,
  requestCalculatedEmi,
  requestCreateApplication,
  requestOfferDetails,
} from "components/ReFi/Application/redux/ApplicationApi";
import { errorMessages } from "lib/constants/constants";
import { toast } from "react-toastify";
import { AnyAction } from "@reduxjs/toolkit";
import { mutateOfferDetails } from "components/ReFi/Application/redux/ApplicationHelper";

function* handleFetchApplication(): Generator<any, void, any> {
  try {
    yield put(updateLoading(true));
    const res = yield call(requestApplication);
    if (res.data.success) {
      yield put(updateApplication(res.data.data));
      yield put(updateNoApplication(false));
      yield put(updateLoading(false));
    } else {
      yield put(updateNoApplication(true));
    }
  } catch (err) {
    console.error(err);
    yield put(updateLoading(false));
  }
}

function* handleCreateApplication(): Generator<any, void, any> {
  try {
    const res = yield call(requestCreateApplication);
    if (res.data.success) {
      yield put(updateSlug(res.data.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG, {
        onClose: () => {
          window.open("/", "_self");
        },
        theme: "light",
      });
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG, {
      onClose: () => {
        window.open("/", "_self");
      }
    });
  }
}

function* handleFetchOfferDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const offerDetailsWithoutEmiResponse = yield call(requestOfferDetails, action.payload);
    const emiResponse = yield call(requestCalculatedEmi, { slug: action.payload });
    if (offerDetailsWithoutEmiResponse?.data && emiResponse?.data?.success) {
      yield put(updateOfferDetails(mutateOfferDetails(
        {
          ...offerDetailsWithoutEmiResponse?.data?.offer,
          emiValue: emiResponse?.data?.data
        }
      )));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* applicationSaga() {
  yield takeLatest(fetchApplication, handleFetchApplication);
  yield takeLatest(createApplication, handleCreateApplication);
  yield takeLatest(fetchOfferDetails, handleFetchOfferDetails);
}

export default applicationSaga;
