import { getUtmData } from "utils/utm_utils";
import { getEventPayload, getFormName, getPageName, getUserPayload } from "utils/events_utils";

declare global {
  interface Window {
    analytics: any;
  }
}

declare global {
  interface Document {
    document: any;
  }
}

const utmDetails = getUtmData(null);

export const segmentReset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
};

export const trackPage = (name: string) => {
  const eventName = "Page Viewed";
  const props = {
    pageName: name || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    adId: utmDetails?.adId || "NA",
    referrer: document?.referrer || "NA",
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackClick = (name: any, props: any) => {
  const eventName = "Click";
  const data = {
    pageName: name || "NA",
    widgetFormat: props.widgetFormat || "NA",
    widgetName: props.widgetName || "NA",
    contentName: props.contentName || "NA",
    contentFormat: props.contentFormat || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    adId: utmDetails?.adId || "NA",
    referrer: document?.referrer || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackIdentity = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.identify(eventName, props);
  }
};

export const trackEvent = (eventName: string, props?: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackForm = async (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = getEventPayload(props);
    const userPayload = getUserPayload(props);
    window.analytics.identify(props?.authId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const trackFileUpload = (props: any) => {
  const eventName = "File Uploaded";
  const data = {
    pageName: getPageName(),
    product: props?.product || "NA",
    fileType: props?.fileType || "NA",
    fileCategory: props?.fileCategory || "NA",
    fileName: props?.fileName || "NA",
    formId: getFormName(),
    referrer: document?.referrer || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackUE = (props: any) => {
  const eventName = "UE";
  const data = {
    product: props?.product || "NA",
    appId: props?.applicationId || "NA",
    pageName: getPageName(),
    finalUEResult: props?.finalUEResult || "NA",
    referrer: document?.referrer || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackCreditReportError = (props: any) => {
  const eventName = "Error";
  const data = {
    product: props?.product || "NA",
    appId: props?.applicationId || "NA",
    pageName: getPageName(),
    referrer: document?.referrer || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackCourseDetailsStatus = (props: any) => {
  const eventName = "Course";
  const data = {
    product: props?.product || "NA",
    appId: props?.applicationId || "NA",
    pageName: getPageName(),
    referrer: document?.referrer || "NA",
    courseStatus: props?.courseStatus || "NA",
    courseRejectionReason: props?.courseRejectionReason || "NA",
  };
  const userPayload = {
    university: props?.universityName || "NA",
    campus: props?.campusName || "NA",
    course: props?.courseName || "NA",
    courseStartDate: props?.courseStartDate || "NA",
    courseEndDate: props?.courseEndDate || "NA",
  };

  if (window.analytics) {
    window.analytics.identify(props?.authId, userPayload);
    window.analytics.track(eventName, data);
  }
};

export const trackOtpVerification = (props: any) => {
  const eventName = "OTP Verification";
  const data = {
    verificationStatus: props?.verificationStatus || "NA",
    product: props?.product || "NA",
    otpType: props?.otpType || "NA",
    appId: props?.applicationId || "NA",
    pageName: getPageName(),
    referrer: document?.referrer || "NA",
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};
