import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployedProfileDocument {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
  subStage: string
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
  subStage: "",
} as EmployedProfileDocument;

export const saveForm = createAction<Record<string, any>>(
  "employedProfileDocument/saveForm"
);

export const fetchEmployedProfileDocument = createAction<string>(
  "employedProfileDocument/fetchEmployedProfileDocument"
);

export const fetchSubStage = createAction(
  "employedProfileDocument/fetchSubStage"
);

const employedProfileDocumentSlice = createSlice({
  name: "employedProfileDocument",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateSubStage(state, action: PayloadAction<string>) {
      const subStage = action.payload;
      return { ...state, subStage };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  updateSubStage,
} = employedProfileDocumentSlice.actions;
export default employedProfileDocumentSlice.reducer;
