import { all } from "redux-saga/effects";
import refiSaga from "components/ReFi/ReFiSaga";

function* rootSaga() {
  yield all([
    refiSaga(),
  ]);
}

export default rootSaga;
