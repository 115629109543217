import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentFailureReason } from "components/common/PaymentFailedReason";

export interface PFPendingStateSchema {
  outstandingFees: any
  isSaving: boolean;
  nextState: string;
  screen: string;
  clientSecret: string
  orderId: string
  isPaymentFailed: boolean
  processingPayment: boolean
  paymentFailureReason: PaymentFailureReason | null
  isCreatingOrder: boolean
}

const initialState = {
  outstandingFees: null,
  isSaving: false,
  nextState: "",
  screen: "",
  clientSecret: "",
  orderId: "",
  isPaymentFailed: false,
  processingPayment: false,
  paymentFailureReason: null,
  isCreatingOrder: false
} as PFPendingStateSchema;

export const fetchOutstandingFees = createAction<string>(
  "PFPending/fetchOutstandingFees"
);

export const createOrder = createAction<string>(
  "PFPending/createOrder"
);

const PFPendingSlice = createSlice({
  name: "PFPending",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateOutstandingFees(state, action: PayloadAction<any | null>) {
      const outstandingFees = action.payload;
      return { ...state, outstandingFees };
    },
    updateClientSecret(state, action: PayloadAction<any | null>) {
      const clientSecret = action.payload;
      return { ...state, clientSecret };
    },
    updateOrderId(state, action: PayloadAction<any | null>) {
      const orderId = action.payload;
      return { ...state, orderId };
    },
    updateIsPaymentFailed(state, action: PayloadAction<any | null>) {
      const isPaymentFailed = action.payload;
      return { ...state, isPaymentFailed };
    },
    updateProcessingPayment(state, action: PayloadAction<any | null>) {
      const processingPayment = action.payload;
      return { ...state, processingPayment };
    },
    updatePaymentFailureReason(state, action: PayloadAction<any | null>) {
      const paymentFailureReason = action.payload;
      return { ...state, paymentFailureReason };
    },
    updateIsCreatingOrder(state, action: PayloadAction<any | null>) {
      const isCreatingOrder = action.payload;
      return { ...state, isCreatingOrder };
    },
  },
});

export const {
  updateOutstandingFees,
  toggleIsSaving,
  updateNextState,
  updateScreen,
  updateClientSecret,
  updateOrderId,
  updateIsPaymentFailed,
  updateProcessingPayment,
  updatePaymentFailureReason,
  updateIsCreatingOrder
} = PFPendingSlice.actions;
export default PFPendingSlice.reducer;
