import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestGetColleges = () =>
  axios.request({
    method: "get",
    url: "/refinance/api/springRoute?path=refi/colleges/USA",
  });

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/courseDetails",
    data: values,
  });

export const requestSaveLead = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/captureLead",
    data: values,
  });

export const requestFetchCollege = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          id
          profile{
            email
          }
          course {
            name
            campus {
              college {
                name
              }
              name
            }
          }
          applicationCourseDetails {
            courseName
            college {
              name
            }
            stem
          }
          userCollegeCapture {
            id,
            course,
            campus,
            college
          }
        }
      }
    `,
    variables: { slug },
  });
