import { call, put, takeLatest } from "redux-saga/effects";
import { fetchMmpDetails,
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateOffer,
  updateMmpDuration,
  updateScreen,
  updateInitialValues } from "components/ReFi/MinimumMonthlyPayment/redux/MmpDetailsSlice";

import { AnyAction } from "@reduxjs/toolkit";
import { requestMmpDetails, requestSaveForm } from "components/ReFi/MinimumMonthlyPayment/redux/MmpDetailsApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchMmpDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestMmpDetails, action.payload);
    if (res.data) {
      yield put(updateOffer(res.data.application.offer));
      yield put(updateMmpDuration(res.data.application.refiApplication.mmpDuration));
      yield put(updateScreen(res.data.application.applicationState.screen));
      yield put(updateInitialValues(res.data.offer));
    }
  } catch (err) {
    console.error(err);
  }
}

function* mmpDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchMmpDetails, handleFetchMmpDetails);
}

export default mmpDetailsSaga;
