import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/graduationDetails",
    data: values,
  });

export const requestEducationDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          hasScholarships
          scholarships{
              amount
          }
          profile {
            testScores{
              test
              score
            }
            educations{
              college
              year
              course
              gpa
              gpaBase
              backlogCount
              gapYearCount
              cgpa
              cgpaBase
            }
            highestQualification
            aptitudeTest
            englishTest
            
          }
          applicationState {
            screen
          }
        }
      }
    `,
    variables: { slug },
  });
