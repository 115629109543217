import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/addressDetails",
    data: values,
  });

export const requestAddressDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
    query ($slug: String){
      application(slug: $slug){
          id
          cosignerAttempt
          profile{
              firstName
              currentAddress{
                  line1
                  line2
                  pincode
                  state
                  city
                  country
              }
          }
          cosigner1{
              currentAddress{
                  line1
                  line2
                  pincode
                  state
                  city
                  country
              }
          }
          cosigner2{
              currentAddress{
                  line1
                  line2
                  pincode
                  state
                  city
                  country
              }
          }
          reference1{
              name
              phone
              relationship
          }
          reference2{
              name
              phone
              relationship
          }
          applicationState{
              screen
          }
      }
  }`,
    variables: { slug },
  });
