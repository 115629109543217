import { call, put, takeLatest } from "redux-saga/effects";
import {
  getColleges,
  setColleges,
  saveForm,
  saveLead,
  toggleIsSaving,
  updateNextState,
  updateCollegeName,
  updateCourseName,
  fetchCollege,
  updateIsEmailValidated,
  updateNotEligibleReason,
  updateCampusName,
  updateApplicationId,
} from "components/ReFi/CourseDetails/redux/CourseDetailsSlice";
import {
  requestGetColleges,
  requestSaveForm,
  requestFetchCollege,
  requestSaveLead,
} from "components/ReFi/CourseDetails/redux/CourseDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";
import { trackCourseDetailsStatus, trackEvent } from "scripts/segment";

function* handleGetCollege(): Generator<any, void, any> {
  try {
    const res = yield call(requestGetColleges);
    yield put(setColleges(res?.data));
  } catch (err) {
    console.error(err);
  }
}

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      if (res?.data?.data?.status === "ELIGIBLE") {
        yield put(updateNextState(res?.data?.data?.state));
        yield call(trackCourseDetailsStatus, {
          product: "Refi",
          courseStatus: "Course Accepted",
          ...action.payload,
        });
        yield call(trackEvent, "Refi Course Accepted");
      } else {
        if (res?.data?.data?.reason) {
          yield put(updateNotEligibleReason("You can apply again after completing at least 1 year of the course"));
        }
        yield put(updateNextState(res?.data?.data?.status));
        yield call(trackCourseDetailsStatus, {
          product: "Refi",
          courseStatus: "Course Rejected",
          courseRejectionReason: "Unsupported",
          ...action.payload,
        });
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleSaveLead(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveLead, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState("reject"));
      yield call(trackCourseDetailsStatus, {
        product: "Refi",
        courseStatus: "Course Rejected",
        courseRejectionReason: "Country",
        ...action.payload,
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(toggleIsSaving(false));
  }
}
function* handleFetchCollege(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestFetchCollege, action.payload);
    if (res.data) {
      const { course, userCollegeCapture, applicationCourseDetails } = res?.data?.application || {};
      if (applicationCourseDetails) {
        yield put(updateCollegeName(applicationCourseDetails?.college?.name));
        yield put(updateCourseName(applicationCourseDetails?.courseName));
      } else if (userCollegeCapture) {
        yield put(updateCollegeName(userCollegeCapture?.college));
        yield put(updateCourseName(userCollegeCapture?.course));
        yield put(updateCampusName(userCollegeCapture?.campus));
      } else if (course) {
        yield put(updateCollegeName(course?.campus?.college?.name));
        yield put(updateCourseName(course?.name));
        yield put(updateCampusName(course?.campus?.name));
      }
      yield put(updateIsEmailValidated(res?.data?.application?.profile?.email));
      yield put(updateApplicationId(res?.data?.application?.id));
    }
  } catch (err) {
    console.error(err);
  }
}

function* courseDetailsSaga() {
  yield takeLatest(getColleges.type, handleGetCollege);
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(saveLead, handleSaveLead);
  yield takeLatest(fetchCollege, handleFetchCollege);
}

export default courseDetailsSaga;
