import { call, put, takeLatest } from "redux-saga/effects";
import { cosignerStatus } from "lib/constants/constants";
import { toast } from "react-toastify";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  fetchCosignerDetails,
  updateInitialValues,
  updateStatus,
  updateShowExperianErrorModal,
  updateIsSecondaryPhoneError,
  updateShowExperianMismatchScreen,
  updateMessage,
  updateScreen,
  updateCosignerAttempt,
  updateCosigner1Pan,
} from "components/ReFi/CosignerDetails/redux/CosignerDetailsSlice";
import { requestSaveForm, requestCosignerDetails } from "components/ReFi/CosignerDetails/redux/CosignerDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";
import { logEvent } from "components/Analytics/google-analytics";
import { fireEvent } from "utils/ga";
import { trackCreditReportError, trackEvent, trackForm, trackUE } from "scripts/segment";
import { stages } from "DataMapper/Refi/Stages";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const { applicationId, authId, slug } = action.payload;
    const res = yield call(requestSaveForm, action.payload);
    const res2 = yield call(requestCosignerDetails, slug);
    if (res.data.success && res2.data) {
      if (res2.data.application?.cosignerAttempt === 1) {
        const { cosigner1 } = res2.data.application;
        yield call(trackForm, "Form Filled", {
          product: "Refi",
          cosigner1FirstName: cosigner1?.firstName,
          cosigner1Relationship: cosigner1?.relationship,
          cosigner1PhoneNumber: cosigner1?.phone,
          cosigner1Email: cosigner1?.email,
          cosignerAttempt: res2.data.application?.cosignerAttempt,
          applicationId,
          leadState: res?.data?.data?.status === cosignerStatus.APPROVED ? res?.data?.data?.stage : stages.COSIGNER_DETAILS,
          authId,
          cosignerStatus: res?.data?.data?.status === cosignerStatus.COSIGNER_REJECTED ? cosignerStatus.REJECTED : res.data.data.status,
          finalUEResult: res?.data?.data?.status === cosignerStatus.COSIGNER_REJECTED ? "SECOND_COSIGNER_NEEDED" : res.data.data.status,
        });
      } else if (res2.data.application?.cosignerAttempt === 2) {
        const { cosigner2 } = res2.data.application;
        yield call(trackForm, "Form Filled", {
          product: "Refi",
          cosigner2FirstName: cosigner2?.firstName,
          cosigner2Relationship: cosigner2?.relationship,
          cosigner2PhoneNumber: cosigner2?.phone,
          cosigner2Email: cosigner2?.email,
          cosignerAttempt: res2.data.application?.cosignerAttempt,
          applicationId,
          leadState: res?.data?.data?.status === cosignerStatus.APPROVED ? res?.data?.data?.stage : stages.COSIGNER_DETAILS,
          authId,
          cosignerStatus: res?.data?.data?.status === cosignerStatus.COSIGNER_REJECTED ? cosignerStatus.REJECTED : res.data.data.status,
          finalUEResult: res?.data?.data?.status === cosignerStatus.COSIGNER_REJECTED ? "SECOND_COSIGNER_NEEDED" : res.data.data.status,
        });
      }
    }
    if (res.data.success) {
      if (res.data.data.status === "COSIGNER_REJECTED") {
        yield put(updateStatus(res.data.data.status));
        yield put(updateNextState(res.data.data.stage));
        window.location.reload();
      } else if (res.data.data.status === "REJECTED") {
        logEvent("refinance", "Co-signer Details Completion", "UE Rejected");
        yield put(updateNextState("APPLICATION_DECLINED"));
        yield call(trackUE, {
          product: "Refi",
          applicationId,
          finalUEResult: "REJECTED"
        });
      } else {
        yield call(trackEvent, "Refi UE Accept");
        logEvent("refinance", "Co-signer Details Completion", "UE Accepted");
        fireEvent("APP_UE_ACCEPTED");
        yield put(updateNextState(res.data.data.stage));
        yield call(trackUE, {
          product: "Refi",
          applicationId,
          finalUEResult: "ACCEPTED"
        });
      }
    } else {
      yield call(trackCreditReportError, {
        product: "Refi",
        applicationId,
      });
      if (res.data?.data) {
        if (res.data?.data?.code === 10) {
          yield put(updateShowExperianMismatchScreen(true));
          yield put(updateIsSecondaryPhoneError(true));
          yield put(updateMessage(res.data.data.message));
        } else {
          yield put(updateShowExperianErrorModal(true));
          yield put(updateIsSecondaryPhoneError(true));
        }
      } else {
        toast.error(res.data?.message);
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchCosignerDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestCosignerDetails, action.payload);
    if (res.data) {
      yield put(updateCosignerAttempt(res.data.application?.cosignerAttempt));
      if (res.data.application.applicationState.unedrwriting === "COSIGNER_REJECTED" || res.data.application.applicationState.unedrwriting === "REJECTED") {
        yield put(updateStatus(res.data.application.applicationState.underwriting));
        yield put(updateInitialValues(res.data.application.cosigner2));
      } else {
        yield put(updateStatus(res.data.application.applicationState.underwriting));
        if (res.data.application.cosignerAttempt <= 1) {
          yield put(updateInitialValues(res.data.application.cosigner1));
        } else {
          yield put(updateInitialValues(res.data.application.cosigner2));
        }
      }
      yield put(updateScreen(res.data.application.applicationState.screen));
      if (res.data.application.cosignerAttempt === 1) {
        yield put(updateCosigner1Pan(res.data.application.cosigner1.pan));
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* cosignerDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchCosignerDetails, handleFetchCosignerDetails);
}

export default cosignerDetailsSaga;
