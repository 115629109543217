export const stages = {
  COURSE_DETAILS: "COURSE_DETAILS",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  PROFESSIONAL_DETAILS: "PROFESSIONAL_DETAILS",
  COSIGNER_DETAILS: "COSIGNER_DETAILS",
  OFFER_DETAILS: "OFFER_DETAILS",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  COSIGNER_DOCUMENTS_UPLOAD: "COSIGNER_DOCUMENTS_UPLOAD",
  OFFER_CONFIRMATION: "OFFER_CONFIRMATION",
  EMPLOYMENT: "EMPLOYMENT",
  REFINANCE_PERSONAL_DETAILS: "REFINANCE_PERSONAL_DETAILS",
  REFINANCE_BUREAU_DETAILS: "REFINANCE_BUREAU_DETAILS",
  REFINANCE_OFFER_DETAILS: "REFINANCE_OFFER_DETAILS",
  REFINANCE_PROFILE_DOCUMENT: "REFINANCE_PROFILE_DOCUMENT",
  REFINANCE_COSIGNER_DETAILS: "REFINANCE_COSIGNER_DETAILS",
  REFINANCE_COSIGNER_DOCUMENT: "REFINANCE_COSIGNER_DOCUMENT",
  REFINANCE_EXAM_SCORE_DETAILS: "REFINANCE_EXAM_SCORE_DETAILS",
  REFINANCE_OFFER_CONFIRMATION: "REFINANCE_OFFER_CONFIRMATION",
  REFINANCE_SENT_TO_CREDIT: "REFINANCE_SENT_TO_CREDIT",
  REFINANCE_FINAL_NEGOTIATED_OFFER: "REFINANCE_FINAL_NEGOTIATED_OFFER",
  REFINANCE_SANCTION_GENERATED: "REFINANCE_SANCTION_GENERATED",
  REFINANCE_PF_PENDING: "REFINANCE_PF_PENDING",
  REFINANCE_POST_SANCTION_NEGOTIATION: "REFINANCE_POST_SANCTION_NEGOTIATION",
  REFINANCE_BANK_DETAILS_COLLECTION: "REFINANCE_BANK_DETAILS_COLLECTION",
  REFINANCE_OK_TO_DISBURSE: "REFINANCE_OK_TO_DISBURSE",
  REFINANCE_CONTRACT_REVIEW: "REFINANCE_CONTRACT_REVIEW",
  REFINANCE_CONTRACT_PENDING: "REFINANCE_CONTRACT_PENDING",
  REFINANCE_REJECTED: "REFINANCE_REJECTED",
  REFINANCE_CONTRACT_APPROVED: "REFINANCE_CONTRACT_APPROVED",
  REFINANCE_HELD_BY_CREDIT: "REFINANCE_HELD_BY_CREDIT"
};

export const refinanceProfileDocumentSubStates = {
  REFINANCE_EDUCATION_DOCUMENT: "REFINANCE_EDUCATION_DOCUMENT",
  REFINANCE_PERSONAL_DOCUMENT: "REFINANCE_PERSONAL_DOCUMENT",
  REFINANCE_FINANCE_DOCUMENT: "REFINANCE_FINANCE_DOCUMENT",
};

export const applicationStatus = {
  DECLINED: "application_declined",
  NOT_ELIGIBLE: "not_eligible"
};

export const underwritingStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COSIGNER_NEEDED: "COSIGNER_NEEDED",
  COSIGNER_REJECTED: "COSIGNER_REJECTED",
};

export const applicationStagesPostOfferConfirmation = [
  stages.OFFER_CONFIRMATION,
  stages.REFINANCE_OFFER_CONFIRMATION,
  stages.REFINANCE_SENT_TO_CREDIT,
  stages.REFINANCE_FINAL_NEGOTIATED_OFFER,
  stages.REFINANCE_SANCTION_GENERATED,
  stages.REFINANCE_PF_PENDING,
  stages.REFINANCE_POST_SANCTION_NEGOTIATION,
  stages.REFINANCE_BANK_DETAILS_COLLECTION,
  stages.REFINANCE_OK_TO_DISBURSE,
  stages.REFINANCE_CONTRACT_REVIEW,
  stages.REFINANCE_CONTRACT_PENDING,
  stages.REFINANCE_REJECTED,
  stages.REFINANCE_CONTRACT_APPROVED,
  stages.REFINANCE_HELD_BY_CREDIT,
];
