import { createWrapper } from "next-redux-wrapper";
import createSagaMiddleware, { Task } from "redux-saga";
import { rootReducer } from "redux/rootReducer";
import rootSaga from "redux/rootSaga";
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";

export interface SagaStore extends EnhancedStore {
  sagaTask: Task;
}

export const makeStore = () => {
  // 1: Create the middleware
  const sagaMiddleware = createSagaMiddleware();

  // 2: Add an extra parameter for applying middleware:
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware]),
  });

  // 3: Run your sagas on server
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  // 4: now return the store:
  return store;
};

export const wrapper = createWrapper<SagaStore>(makeStore as any);
