import { gql } from "@apollo/client";
import apolloClient from "apollo";
import axios from "axios";

export const requestSaveContractPendingForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/upload-signed-documents",
    data: values,
  });

export const requestSavedDocuments = (slug: string) => apolloClient.query({
  query: gql`
        query ($slug: String!) {
          application(slug: $slug) {
            profile{
              documents{
                name
                keyName
                size
                url
              }
            }
          }
        }
      `,
  variables: { slug },
  fetchPolicy: "network-only"
});
