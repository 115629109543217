import cloneDeep from "lodash/cloneDeep";

export const mutateInitialValues = (payload: Record<string, any>) => {
  const data = cloneDeep(payload);
  const testScores = data.profile.testScores.reduce((a: any, c: any) => {
    if (c.test === `${payload.profile.aptitudeTest}QUANT`) {
      a.aptitudeQuantScore = c.score;
    } else if (c.test === `${payload.profile.aptitudeTest}VERBAL`) {
      a.aptitudeVerbalScore = c.score;
    } else if (c.test === payload.profile.englishTest) {
      a.languageTestScore = c.score;
    }
    a.languageTest = payload.profile.englishTest;
    a.aptitudeTest = payload.profile.aptitudeTest;
    return a;
  }, {});
  const educationDetails = data.profile.educations.reduce((a: any, c: any) => {
    if (c.course === "X") {
      a.class10Score = c.gpa;
    } else if (c.course === "XII") {
      a.class12Score = c.gpa;
    }
    return a;
  }, {});
  return { ...testScores, ...educationDetails };
};
