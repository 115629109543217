import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employmentDetails",
    data: values,
  });

export const requestFetchEmploymentDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile {
            employments {
              id
              employer
              designation
              employmentStatus
              employmentType
              employmentCountry
              employmentStartDate
              employmentEndDate
              salary
              currency
              workEmail
            }
          }
          applicationState {
            screen
          }
        }
      }
    `,
    variables: { slug },
  });
