import basePath from "lib/helpers/basePath";

export const contactTypes = {
  CALL_RM: "CALL_RM",
  WHATSAPP: "WHATSAPP",
  MAIL: "MAIL",
};

export const getContactImageType: Record<string, any> = {
  WHATSAPP: {
    disable: "/assets/images/icon-disable-whatsapp.svg",
    hover: "/assets/images/icon-whatsapp.svg",
    default: "/assets/images/icon-default-whatsapp.svg",
    text: "Whatsapp",
  },
  MAIL: {
    disable: "/assets/images/icon-disable-mail.svg",
    hover: "/assets/images/icon-primary-mail.svg",
    default: "/assets/images/icon-mail.svg",
    text: "Mail",
  },
  CALL_RM: {
    disable: "/assets/images/icon-disable-phone.svg",
    hover: "/assets/images/icon-primary-phone.svg",
    default: "/assets/images/icon-default-phone.svg",
    text: "Call your relationship manager",
  },
};

export const nextSteps: any = {
  REFINANCE_OFFER_CONFIRMATION: [
    {
      title: "Download your Sanction letter",
      image: `${basePath}/assets/icons/refi/download-blue.svg`,
    },
    {
      title: "Download your Loan agreement",
      image: `${basePath}/assets/icons/refi/download-blue.svg`,
    },
  ],
  REFINANCE_CONTRACT_PENDING: [
    {
      title: "Download your Loan agreement & ACH form",
      image: `${basePath}/assets/icons/refi/download-blue.svg`,
    },
    {
      title: "The agreement printed should be single-sided.",
      image: `${basePath}/assets/icons/refi/document.svg`,
    },
    {
      title: "Fill the highlighted fields using a blue/black ball pen.",
      image: `${basePath}/assets/icons/refi/signature.svg`,
    },
    {
      title:
        "Make sure your signature on the application matches the signature on your passport.",
      image: `${basePath}/assets/icons/tick-v2.svg`,
    },
    {
      title:
        "Make sure the Cosigner’s signature on the application matches the signature on the PAN Card",
      image: `${basePath}/assets/icons/tick-v2.svg`,
    },
    {
      title:
        "Put your Initials in 4 places. Suppose your name is Sharad Kumar so you have to put S.K",
      image: `${basePath}/assets/icons/refi/edit.svg`,
    },
    {
      title: "Sign and Upload the pdf file of the agreement to proceed.",
      image: `${basePath}/assets/icons/refi/upload-blue.svg`,
    },
  ],
  REFINANCE_POST_SANCTION_NEGOTIATION_OR_REFINANCE_SANCTION_GENERATED: [
    {
      title: "Download contract",
      image: `${basePath}/assets/icons/refi/download-blue.svg`,
    },
    {
      title: "Upload signed contract",
      image: `${basePath}/assets/icons/refi/upload-blue.svg`,
    },
    {
      title: "Request funds",
      image: `${basePath}/assets/icons/refi/request.svg`,
    },
  ],
};

export const slideSteps: any = [
  {
    title: "Step 1",
    subTitle: "Raise Disbursement Request",
    image: `${basePath}/assets/icons/refi/disbursement-step-one.svg`,
  },
  {
    title: "Step 2",
    subTitle: "We approve and money transfers to your account",
    image: `${basePath}/assets/icons/refi/disbursement-step-two.svg`,
  },
];

export enum PFCollectionTypes {
  COLLECT_PF_UPFRONT = "COLLECT_PF_UPFRONT",
  COLLECT_PF_LOAN_FEE = "COLLECT_PF_LOAN_FEE",
}

export const flatRoiRefi = process.env.FLAT_ROI_REFI;
