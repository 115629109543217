import { getUtmData } from "utils/utm_utils";

const utmDetails = getUtmData(null);

export const pageName = {
  REFI_LANDING_PAGE: "Refi Landing Page",
  REFI_START_APPLICATION: "Refi Start Application",
  REFI_UNIVERSITY_AND_COURSE_DETAILS: "Refi University and Course Details",
  REFI_PERSONAL_DETAILS: "Refi Personal Details",
  REFI_PROFESSIONAL_DETAILS: "Refi Professional Details",
  REFI_ACADEMIC_DETAILS: "Refi Academic Details",
  REFI_COSIGNER_DETAILS: "Refi Cosigner Details",
  REFI_LOAN_OFFER_DETAILS: "Refi Loan Offer Details",
  REFI_MMP: "Refi MMP",
  REFI_ADDRESS_DETAILS: "Refi Address Details",
  REFI_APPLICANT_DOCUMENTS: "Refi Applicant Documents",
  REFI_COSIGNER_DOCUMENTS: "Refi Cosigner Documents",
  REFI_LOAN_OFFER_CONFIRMATION: "Refi Loan Offer Confirmation",
  REFI_DASHBOARD: "Refi Dashboard",
  REFI_NOT_ELIGIBLE: "Refi Not Eligible",
  REFI_APPLICATION_DECLINED: "Refi Application Declined",
};

export const getPageName = () => {
  if (process.browser) {
    const path = window?.location?.pathname || "";
    const screenStage = path.split("/").slice(-1).pop()?.toUpperCase();
    if (screenStage === "COURSE_DETAILS") {
      return pageName.REFI_UNIVERSITY_AND_COURSE_DETAILS;
    } if (screenStage === "PERSONAL_DETAILS") {
      return pageName.REFI_PERSONAL_DETAILS;
    } if (screenStage === "PROFESSIONAL_DETAILS") {
      return pageName.REFI_PROFESSIONAL_DETAILS;
    } if (screenStage === "EDUCATION_DETAILS") {
      return pageName.REFI_ACADEMIC_DETAILS;
    } if (screenStage === "COSIGNER_DETAILS") {
      return pageName.REFI_COSIGNER_DETAILS;
    } if (screenStage === "OFFER_DETAILS") {
      return pageName.REFI_LOAN_OFFER_DETAILS;
    } if (screenStage === "PAYMENT_DETAILS") {
      return pageName.REFI_MMP;
    } if (screenStage === "ADDRESS_DETAILS") {
      return pageName.REFI_ADDRESS_DETAILS;
    } if (screenStage === "DOCUMENTS_UPLOAD") {
      return pageName.REFI_APPLICANT_DOCUMENTS;
    } if (screenStage === "COSIGNER_DOCUMENTS_UPLOAD") {
      return pageName.REFI_COSIGNER_DOCUMENTS;
    } if (screenStage === "OFFER_CONFIRMATION") {
      return pageName.REFI_LOAN_OFFER_CONFIRMATION;
    } if (screenStage === "REFI") {
      return pageName.REFI_START_APPLICATION;
    } if (screenStage === "REFINANCE") {
      return pageName.REFI_LANDING_PAGE;
    }
  }
  return null;
};

export const getFormName = getPageName;

export const getEventPayload = (props: Record<string, any>) => {
  const commonProperties = {
    product: props?.product || "NA",
    appId: props?.applicationId || NaN,
    pageName: getPageName(),
    formId: getFormName(),
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    adId: utmDetails?.adId || "NA",
    referrer: document?.referrer || "NA",
  };
  switch (getPageName()) {
    case pageName.REFI_UNIVERSITY_AND_COURSE_DETAILS:
      return {
        ...commonProperties,
        university: props?.universityName || "NA",
        campus: props?.campusName || "NA",
        course: props?.courseName || "NA",
        loanAmount: props?.balanceLoanAmt,
      };
    case pageName.REFI_COSIGNER_DETAILS:
      return {
        ...commonProperties,
        cosignerStatus: props?.cosignerStatus || "NA",
        finalUEResult: props?.finalUEResult || "NA",
        cosignerAttempt: props?.cosignerAttempt,
        cosigner1FirstName: props?.cosigner1FirstName || "NA",
        cosigner1Relationship: props?.cosigner1Relationship || "NA",
        cosigner1PhoneNumber: props?.cosigner1PhoneNumber || "NA",
        cosigner1Email: props?.cosigner1Email || "NA",
        cosigner2FirstName: props?.cosigner2FirstName || "NA",
        cosigner2Relationship: props?.cosigner2Relationship || "NA",
        cosigner2PhoneNumber: props?.cosigner2PhoneNumber || "NA",
        cosigner2Email: props?.cosigner2Email || "NA",
      };
    case pageName.REFI_LOAN_OFFER_DETAILS:
      return {
        ...commonProperties,
        offeredROI: props?.offeredROI,
        offeredLoanAmount: props?.offeredLoanAmount,
        tenure: props?.loanTenure,
      };
    case pageName.REFI_MMP:
      return {
        ...commonProperties,
        mmpAmount: props?.minimumMonthlyPayments * 10,
      };
    case pageName.REFI_LANDING_PAGE:
      return {
        ...commonProperties,
        employmentStatus: props?.employmentStatus || "NA",
        phoneNumber: props?.phoneNumber || "NA",
      };
    default: return commonProperties;
  }
};

export const getUserPayload = (props: Record<string, any>) => {
  const commonProperties = {
    leadState: props?.leadState || "NA",
    citizenship: "India"
  };
  switch (getPageName()) {
    case pageName.REFI_UNIVERSITY_AND_COURSE_DETAILS:
      return {
        ...commonProperties,
        email: props?.email,
        country: props?.country || "NA",
        USState: props?.stateOfResidence || "NA",
        courseStartDate: props?.courseStartDate,
        courseEndDate: props?.courseEndDate,
      };
    case pageName.REFI_PERSONAL_DETAILS:
      return {
        ...commonProperties,
        firstName: props?.firstName,
        lastName: props?.lastName,
        gender: props?.gender || "NA",
        birthDate: props?.dob,
      };
    case pageName.REFI_PROFESSIONAL_DETAILS:
      return {
        ...commonProperties,
        company: props?.nameOfEmployer || "NA",
        grossAnnualSalary: props?.grossAnnualSalary,
      };
    case pageName.REFI_LOAN_OFFER_DETAILS:
      return {
        ...commonProperties,
        lenderName: props?.lenderName || "NA",
        currentROI: props?.lenderInterestRate,
      };
    default: return commonProperties;
  }
};
