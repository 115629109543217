import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";
import basePath from "lib/helpers/basePath";

export const requestApplication = () =>
  axios.request({
    method: "get",
    url: `${basePath}/api/springRoute?path=refi/fetchRefiApplicationCard`,
  });

export const requestCalculatedEmi = (data: Record<string, any>) =>
  axios.request({
    method: "post",
    url: `${basePath}/api/springRoute?path=loan-component/v2/fetch-emi`,
    data,
  });

export const requestCreateApplication = () =>
  axios.request({
    method: "get",
    url: `${basePath}/api/springRoute?path=refi/fetchRefiApplication`,
  });

export const requestApplicationId = async (slug: string) => apolloClient.query({
  query: gql`
        query ($slug: String!) {
          application(slug: $slug) {
            id
          }
        }
      `,
  variables: { slug },
});

export const requestOfferDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        offer(slug: $slug) {
          interestRate
          loanAmount
          loanTenure
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "network-only"
  });
