import cloneDeep from "lodash/cloneDeep";

export const mutateValues = (payload: Record<string, any>) => {
  const docs = cloneDeep(payload);
  delete docs?.addressProof;
  delete docs?.slug;
  delete docs?.subState;
  const documents = Object.keys(docs).reduce((a: any, c: any) => {
    if (Array.isArray(payload[c])) {
      payload[c].forEach((file: any) => {
        const obj = { ...file };
        obj.keyName = c;
        a.push(obj);
      });
    } else {
      const obj = { ...payload[c] };
      obj.keyName = c;
      a.push(obj);
    }
    return a;
  }, []);
  return {
    slug: payload.slug,
    documents,
  };
};

export const formattedInitialValues = (initialValues: Record<string, any>) => {
  const formattedValues = initialValues.reduce((updatedObject: any, currentValue: any) => {
    const { name, size, url } = currentValue;
    const checkIfMultiUploadInput = initialValues.filter((value: any) => value.keyName === currentValue.keyName).length > 0;
    if (checkIfMultiUploadInput) {
      if (currentValue.keyName in updatedObject) {
        updatedObject[currentValue.keyName] = [...updatedObject[currentValue.keyName], { name, size, url }];
      } else {
        updatedObject[currentValue.keyName] = [{ name, size, url }];
      }
    } else {
      updatedObject[currentValue.keyName] = { name, size, url };
    }
    return updatedObject;
  }, {});
  return formattedValues;
};
