import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPersonalDetails,
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  updateShowExperianErrorModal,
  updateShowExperianMismatchScreen,
  updateIsSecondaryPhoneError,
  updateMessage,
} from "components/ReFi/PersonalDetails/redux/PersonalDetailsSlice";
import {
  requestSaveForm,
  requestPersonalDetails,
} from "components/ReFi/PersonalDetails/redux/PersonalDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";
import { trackCreditReportError, trackForm } from "scripts/segment";
import { stages } from "DataMapper/Refi/Stages";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const { applicationId } = action.payload;
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState(res?.data?.data));
      yield call(trackForm, "Form Filled", {
        product: "Refi",
        ...action.payload,
        gender: action?.payload?.gender === "M" ? "male" : "female",
        leadState: stages.PROFESSIONAL_DETAILS,
      });
    } else {
      yield call(trackCreditReportError, {
        product: "Refi",
        applicationId,
      });
      if (res?.data?.data?.code === 10) {
        yield put(updateShowExperianMismatchScreen(true));
        yield put(updateIsSecondaryPhoneError(true));
        yield put(updateMessage(res?.data?.data?.message));
      } else {
        yield put(updateShowExperianErrorModal(true));
        yield put(updateIsSecondaryPhoneError(true));
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchPersonalDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestPersonalDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(res?.data?.application?.profile));
      yield put(updateScreen(res?.data?.application?.applicationState?.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* personalDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchPersonalDetails, handleFetchPersonalDetails);
}

export default personalDetailsSaga;
