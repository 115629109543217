import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";
import { refinanceProfileDocumentSubStates } from "DataMapper/Refi/Stages";

export const requestSaveForm = (values: Record<string, any>, subState: string) => {
  const subStateSaveApiMapper = {
    [refinanceProfileDocumentSubStates.REFINANCE_PERSONAL_DOCUMENT]: "uploadPersonalDocuments",
    [refinanceProfileDocumentSubStates.REFINANCE_FINANCE_DOCUMENT]: "uploadFinancialDocuments",
    [refinanceProfileDocumentSubStates.REFINANCE_EDUCATION_DOCUMENT]: "uploadEducationDocuments"
  };
  return axios.request({
    method: "post",
    url: `/refinance/api/springRoute?path=refi/employment/${subStateSaveApiMapper[subState]}`,
    data: values,
  });
};

export const requestEmployedProfileDocument = (slug: string) => apolloClient.query({
  query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile{
            documents{
              name
              keyName
              size
              url
            }
          }
          refiApplicationState {
            id
            stage
            status
            underwriting
          }
        }
      }
    `,
  variables: { slug },
  fetchPolicy: "network-only"
});
