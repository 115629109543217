import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import { fetchBankDetails, saveForm, toggleIsSaving, updateBankDetails, updateNextState } from "components/ReFi/EmployedFlow/BankDetailsCollection/redux/BankDetailsCollectionSlice";
import { requestBankDetails, requestSaveForm } from "components/ReFi/EmployedFlow/BankDetailsCollection/redux/BankDetailsCollectionApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = action?.payload;
    const res = yield call(requestSaveForm, values);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchBankDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const slug = action?.payload;
    const res = yield call(requestBankDetails, slug);
    if (res.data.success) {
      yield put(updateBankDetails(res.data.data));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* bankDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchBankDetails, handleFetchBankDetails);
}

export default bankDetailsSaga;
