import {
  CookieReqContext,
  CookieResContext,
  getCookie,
  saveCookie,
} from "utils/cookie_utils";

  type UtmData = {
    utmSource?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmMedium?: string;
    campaignType?: string;
    adId?: string;
    trackingId?: string;
  };

export function getUtmData(ctx: CookieReqContext): UtmData {
  let utmSource;
  let utmCampaign;
  let utmTerm;
  let utmMedium;
  let campaignType;
  let adId;
  let trackingId;
  if (getCookie(ctx, "utmSource")) {
    utmSource = getCookie(ctx, "utmSource") as string;
  }
  if (getCookie(ctx, "utmCampaign")) {
    utmCampaign = getCookie(ctx, "utmCampaign") as string;
  }
  if (getCookie(ctx, "utmTerm")) {
    utmTerm = getCookie(ctx, "utmTerm") as string;
  }
  if (getCookie(ctx, "utmMedium")) {
    utmMedium = getCookie(ctx, "utmMedium") as string;
  }
  if (getCookie(ctx, "campaignType")) {
    campaignType = getCookie(ctx, "campaignType") as string;
  }
  if (getCookie(ctx, "adId")) {
    adId = getCookie(ctx, "adId") as string;
  }
  if (getCookie(ctx, "trackingId")) {
    trackingId = getCookie(ctx, "trackingId") as string;
  }
  return {
    utmSource,
    utmCampaign,
    utmTerm,
    utmMedium,
    campaignType,
    adId,
    trackingId,
  } as UtmData;
}

export function saveUtmData(ctx: CookieResContext, utmData: UtmData) {
  if (utmData.utmSource) {
    saveCookie(ctx, "utmSource", utmData.utmSource);
  }
  if (utmData.utmCampaign) {
    saveCookie(ctx, "utmCampaign", utmData.utmCampaign);
  }
  if (utmData.utmTerm) {
    saveCookie(ctx, "utmTerm", utmData.utmTerm);
  }
  if (utmData.utmMedium) {
    saveCookie(ctx, "utmMedium", utmData.utmMedium);
  }
  if (utmData.campaignType) {
    saveCookie(ctx, "campaignType", utmData.campaignType);
  }
  if (utmData.adId) {
    saveCookie(ctx, "adId", utmData.adId);
  }
  if (utmData.trackingId) {
    saveCookie(ctx, "trackingId", utmData.trackingId);
  }
}
