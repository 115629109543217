import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OfferConfirmationState {
  offer: Record<string, any> | null;
}

const initialState = {
  offer: null,
} as OfferConfirmationState;

export const fetchOfferConfirmationDetails = createAction<string>(
  "offerConfirmationDetails/fetchOfferConfirmationDetails"
);

const OfferConfirmationDetails = createSlice({
  name: "offerConfirmationDetails",
  initialState,
  reducers: {
    updateOffer(state, action: PayloadAction<Record<string, any>>) {
      const offer = action.payload;
      return { ...state, offer };
    },
  },
});

export const { updateOffer } = OfferConfirmationDetails.actions;
export default OfferConfirmationDetails.reducer;
