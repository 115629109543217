import { all } from "redux-saga/effects";
import courseDetailsSaga from "components/ReFi/CourseDetails/redux/CourseDetailsSaga";
import personalDetailsSaga from "components/ReFi/PersonalDetails/redux/PersonalDetailsSaga";
import employmentDetailsSaga from "components/ReFi/ProfessionalDetails/EmploymentDetails/redux/EmploymentDetailsSaga";
import documentsUploadSaga from "components/ReFi/DocumentsUpload/redux/DocumentsUploadSaga";
import cosignerDocumentsSaga from "components/ReFi/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSaga";
import applicationSaga from "components/ReFi/Application/redux/ApplicationSaga";
import cosignerDetailsSaga from "components/ReFi/CosignerDetails/redux/CosignerDetailsSaga";
import educationDetailsSaga from "components/ReFi/ProfessionalDetails/EducationDetails/redux/EducationDetailsSaga";
import employedAcademicDetailsSaga from "components/ReFi/EmployedFlow/EmployedAcademicDetails/redux/EmployedAcademicDetailsSaga";
import PFPendingSaga from "components/ReFi/EmployedFlow/PFPending/redux/PFPendingSaga";
import offerDetailsSaga from "components/ReFi/OfferDetails/redux/OfferDetailsSaga";
import addressDetailsSaga from "components/ReFi/AddressDetails/redux/AddressDetailsSaga";
import mmpDetailsSaga from "components/ReFi/MinimumMonthlyPayment/redux/MmpDetailsSaga";
import offerConfirmationDetailsSaga from "components/ReFi/FinalLoanOffer/redux/OfferConfirmationSaga";
import employmentSaga from "components/ReFi/Employment/redux/EmploymentSaga";
import employedPersonalDetailsSaga from "components/ReFi/EmployedFlow/EmployedPersonalDetails/redux/EmployedPersonalDetailsSaga";
import employedBureauDetailsSaga from "components/ReFi/EmployedFlow/EmployedBureauDetails/redux/EmployedBureauDetailsSaga";
import employedOfferDetailsSaga from "components/ReFi/EmployedFlow/EmployedOfferDetails/redux/EmployedOfferDetailsSaga";
import employedProfileDocumentSaga from "components/ReFi/EmployedFlow/EmployedProfileDocument/redux/EmployedProfileDocumentSaga";
import employedCosignerDetailsSaga from "components/ReFi/EmployedFlow/EmployedCosignerDetails/redux/EmployedCosignerDetailsSaga";
import employedCosignerDocumentSaga from "components/ReFi/EmployedFlow/EmployedCosignerDocument/redux/EmployedCosignerDocumentSaga";
import employedOfferConfirmationDetailsSaga from "components/ReFi/EmployedFlow/EmployedOfferConfirmation/redux/EmployedOfferConfirmationSaga";
import sanctionGeneratedSaga from "components/ReFi/EmployedFlow/SanctionGenerated/redux/SanctionGeneratedSaga";
import bankDetailsSaga from "components/ReFi/EmployedFlow/BankDetailsCollection/redux/BankDetailsCollectionSaga";
import contractPendingSaga from "components/ReFi/EmployedFlow/ContractPending/redux/ContractPendingSaga";

function* refiSaga() {
  yield all([
    courseDetailsSaga(),
    personalDetailsSaga(),
    employmentDetailsSaga(),
    cosignerDetailsSaga(),
    offerDetailsSaga(),
    mmpDetailsSaga(),
    addressDetailsSaga(),
    documentsUploadSaga(),
    cosignerDocumentsSaga(),
    applicationSaga(),
    educationDetailsSaga(),
    offerConfirmationDetailsSaga(),
    employmentSaga(),
    employedPersonalDetailsSaga(),
    employedBureauDetailsSaga(),
    employedOfferDetailsSaga(),
    employedProfileDocumentSaga(),
    employedCosignerDetailsSaga(),
    employedCosignerDocumentSaga(),
    employedOfferConfirmationDetailsSaga(),
    employedAcademicDetailsSaga(),
    PFPendingSaga(),
    sanctionGeneratedSaga(),
    bankDetailsSaga(),
    contractPendingSaga(),
  ]);
}

export default refiSaga;
