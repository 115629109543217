import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CosignerDocumentsUploadState {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
} as CosignerDocumentsUploadState;

export const saveForm = createAction<Record<string, any>>(
  "cosignerDocumentsUpload/saveForm"
);
export const fetchCosignerDocumentsDetails = createAction<string>(
  "cosignerDocumentsUpload/fetchCosignerDocumentsDetails"
);

const cosignerDocumentsUpload = createSlice({
  name: "cosignerDocumentsUpload",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
  },
});

export const { toggleIsSaving, updateNextState, updateInitialValues, updateScreen } = cosignerDocumentsUpload.actions;
export default cosignerDocumentsUpload.reducer;
