export const mutateInitialValues = (initialValues: Record<string, any>) => {
  const { profile, applicationCourseDetails, refiApplication, userCollegeCapture } = initialValues || {};
  return {
    ...profile,
    ...applicationCourseDetails,
    annualSalary: profile?.currentAnnualSalary || profile?.employments[0]?.salary,
    courseType: applicationCourseDetails?.stem === "true" ? true : applicationCourseDetails?.stem,
    universityName: applicationCourseDetails?.college?.name || userCollegeCapture?.college,
    outstandingLoanAmount: refiApplication?.existingLoanBalance || null
  };
};
