import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AddressDetailsState {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
} as AddressDetailsState;

export const saveForm = createAction<Record<string, any>>(
  "addressDetails/saveForm"
);

export const fetchAddressDetails = createAction<string>(
  "addressDetails/fetchAddressDetails"
);

const addressDetailsSlice = createSlice({
  name: "addressDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
  },
});

export const { toggleIsSaving, updateNextState, updateInitialValues, updateScreen } = addressDetailsSlice.actions;
export default addressDetailsSlice.reducer;
