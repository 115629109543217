import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import { createOrder, fetchOutstandingFees, updateClientSecret, updateIsCreatingOrder, updateOrderId, updateOutstandingFees } from "components/ReFi/EmployedFlow/PFPending/redux/PFPendingSlice";
import { requestCreateOrder, requestOutstandingFees } from "components/ReFi/EmployedFlow/PFPending/redux/PFPendingApi";

function* handleFetchOutstandingFees(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestOutstandingFees, action.payload);
    if (res?.data?.success) {
      yield put(updateOutstandingFees((res.data.data)));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* handleCreateOrder(
  action: AnyAction
): Generator<any, void, any> {
  try {
    yield put(updateIsCreatingOrder(true));
    const res = yield call(requestCreateOrder, action.payload);
    if (res?.data?.success) {
      yield put((updateOrderId(res?.data?.data.orderId)));
      yield put((updateClientSecret(res?.data?.data.clientSecret)));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsCreatingOrder(false));
  }
}

function* PFPendingSaga() {
  yield takeLatest(fetchOutstandingFees, handleFetchOutstandingFees);
  yield takeLatest(createOrder, handleCreateOrder);
}

export default PFPendingSaga;
