import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  fetchUserDocumentsDetails,
  updateInitialValues,
  updateScreen,
  updateAddressProofNeeded
} from "components/ReFi/DocumentsUpload/redux/DocumentsUploadSlice";
import { requestSaveForm, requestUserDocumentsDetails } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadApi";
import { formattedInitialValues, mutateValues } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadHelper";
import { AnyAction } from "@reduxjs/toolkit";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = mutateValues(action.payload);
    const res = yield call(requestSaveForm, values);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchUserDocumentsDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestUserDocumentsDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(formattedInitialValues(res.data.application.profile.documents)));
      yield put(updateScreen(res.data.application.applicationState.screen));
      yield put(updateAddressProofNeeded(res.data.application.extraInformation.addressProof));
    }
  } catch (err) {
    console.error(err);
  }
}

function* documentsUploadSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchUserDocumentsDetails, handleFetchUserDocumentsDetails);
}

export default documentsUploadSaga;
