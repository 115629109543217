import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployedOfferConfirmationDetails {
  offerDetails: Record<string, any> | null;
}

const initialState = {
  offerDetails: null,
} as EmployedOfferConfirmationDetails;

export const fetchEmployedOfferConfirmationDetails = createAction<string>(
  "employedOfferConfirmationDetails/fetchEmployedOfferConfirmationDetails"
);

const employedOfferConfirmationDetailsSlice = createSlice({
  name: "employedOfferConfirmationDetails",
  initialState,
  reducers: {
    updateLoanOfferDetails(state, action: PayloadAction<Record<string, any>>) {
      const offerDetails = action.payload;
      return { ...state, offerDetails };
    },
  },
});

export const {
  updateLoanOfferDetails,
} = employedOfferConfirmationDetailsSlice.actions;
export default employedOfferConfirmationDetailsSlice.reducer;
