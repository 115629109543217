import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employment/examScoreDetails",
    data: values,
  });

export const requestEmployedAcademicDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile{
            educations{
              id
              course
              gpa
            }
            testScores{
              id
              test
              score
            }
            aptitudeTest
            englishTest
          }
          refiApplicationState {
            id
            stage
            status
            underwriting
          }
        }
      }
    `,
    variables: { slug },
  });
