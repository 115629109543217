import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmploymentStatus {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
  activeStudentLoan: boolean | null;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
  activeStudentLoan: null,
} as EmploymentStatus;

export const saveForm = createAction<Record<string, any>>(
  "employment/saveForm"
);

export const fetchEmploymentStatus = createAction<string>(
  "employment/fetchEmploymentStatus"
);

export const saveStudentActiveLoanStatus = createAction<Record<string, any>>(
  "employment/saveStudentActiveLoanStatus"
);

const employmentSlice = createSlice({
  name: "employment",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateActiveStudentLoan(state, action: PayloadAction<boolean | null>) {
      const activeStudentLoan = action.payload;
      return { ...state, activeStudentLoan };
    }
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  updateActiveStudentLoan,
} = employmentSlice.actions;
export default employmentSlice.reducer;
