import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmploymentDetailsState {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
} as EmploymentDetailsState;

export const saveForm = createAction<Record<string, any>>(
  "employmentDetails/saveForm"
);

export const fetchEmploymentDetails = createAction<string>("employmentDetails/fetchEmploymentDetails");

const employmentDetails = createSlice({
  name: "employmentDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    }
  },
});

export const { toggleIsSaving, updateNextState, updateInitialValues, updateScreen } = employmentDetails.actions;
export default employmentDetails.reducer;
