import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/offerDetails",
    data: values,
  });

export const requestOfferDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        offer (slug: $slug) {
          existingLenderName
          existingInterestRate
        }
        application(slug: $slug) {
          id
          country
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
          }
          profile{
            firstName
            lastName
          }
          applicationState{
            screen
          }
        }
      }
    `,
    variables: { slug },
  });
