import { combineReducers } from "@reduxjs/toolkit";
import CourseDetailsReducer from "components/ReFi/CourseDetails/redux/CourseDetailsSlice";
import PersonalDetailsReducer from "components/ReFi/PersonalDetails/redux/PersonalDetailsSlice";
import EmploymentDetailsReducer from "components/ReFi/ProfessionalDetails/EmploymentDetails/redux/EmplymentDetailsSlice";
import CosignerDetailsReducer from "components/ReFi/CosignerDetails/redux/CosignerDetailsSlice";
import OfferDetailsReducer from "components/ReFi/OfferDetails/redux/OfferDetailsSlice";
import MmpDetailsReducer from "components/ReFi/MinimumMonthlyPayment/redux/MmpDetailsSlice";
import AddressDetailsReducer from "components/ReFi/AddressDetails/redux/AddressDetailsSlice";
import DocumentsUploadReducer from "components/ReFi/DocumentsUpload/redux/DocumentsUploadSlice";
import CosignerDocumentsReducer from "components/ReFi/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSlice";
import ApplicationReducer from "components/ReFi/Application/redux/ApplicationSlice";
import EducationDetailsReducer from "components/ReFi/ProfessionalDetails/EducationDetails/redux/EducationDetailsSlice";
import OfferConfirmationDetailsReducer from "components/ReFi/FinalLoanOffer/redux/OfferConfirmationSlice";
import employmentReducer from "components/ReFi/Employment/redux/EmploymentSlice";
import EmployedAcademicDetailsSlice from "components/ReFi/EmployedFlow/EmployedAcademicDetails/redux/EmployedAcademicDetailsSlice";
import PFPendingSlice from "components/ReFi/EmployedFlow/PFPending/redux/PFPendingSlice";
import EmployedPersonalDetailsSlice from "components/ReFi/EmployedFlow/EmployedPersonalDetails/redux/EmployedPersonalDetailsSlice";
import EmployedBureauDetailsSlice from "components/ReFi/EmployedFlow/EmployedBureauDetails/redux/EmployedBureauDetailsSlice";
import EmployedOfferDetailsSlice from "components/ReFi/EmployedFlow/EmployedOfferDetails/redux/EmployedOfferDetailsSlice";
import EmployedProfileDocumentSlice from "components/ReFi/EmployedFlow/EmployedProfileDocument/redux/EmployedProfileDocumentSlice";
import EmployedCosignerDetailsSlice from "components/ReFi/EmployedFlow/EmployedCosignerDetails/redux/EmployedCosignerDetailsSlice";
import EmployedCosignerDocumentSlice from "components/ReFi/EmployedFlow/EmployedCosignerDocument/redux/EmployedCosignerDocumentSlice";
import EmployedOfferConfirmationSlice from "components/ReFi/EmployedFlow/EmployedOfferConfirmation/redux/EmployedOfferConfirmationSlice";
import SanctionGeneratedSlice from "components/ReFi/EmployedFlow/SanctionGenerated/redux/SanctionGeneratedSlice";
import BankDetailsCollectionSlice from "components/ReFi/EmployedFlow/BankDetailsCollection/redux/BankDetailsCollectionSlice";
import ContractPendingSlice from "components/ReFi/EmployedFlow/ContractPending/redux/ContractPendingSlice";

export default combineReducers({
  courseDetails: CourseDetailsReducer,
  personalDetails: PersonalDetailsReducer,
  employmentDetails: EmploymentDetailsReducer,
  cosignerDetails: CosignerDetailsReducer,
  offerDetails: OfferDetailsReducer,
  mmpDetails: MmpDetailsReducer,
  addressDetails: AddressDetailsReducer,
  documentsUpload: DocumentsUploadReducer,
  cosignerDocumentsUpload: CosignerDocumentsReducer,
  application: ApplicationReducer,
  educationDetails: EducationDetailsReducer,
  offerConfirmationDetails: OfferConfirmationDetailsReducer,
  employment: employmentReducer,
  employedPersonalDetails: EmployedPersonalDetailsSlice,
  employedBureauDetails: EmployedBureauDetailsSlice,
  employedOfferDetails: EmployedOfferDetailsSlice,
  employedProfileDocument: EmployedProfileDocumentSlice,
  employedCosignerDetails: EmployedCosignerDetailsSlice,
  employedCosignerDocument: EmployedCosignerDocumentSlice,
  employedOfferConfirmationDetails: EmployedOfferConfirmationSlice,
  employedAcademicDetails: EmployedAcademicDetailsSlice,
  PFPending: PFPendingSlice,
  sanctionGenerated: SanctionGeneratedSlice,
  bankDetailsCollection: BankDetailsCollectionSlice,
  contractPending: ContractPendingSlice,
});
