import axios from "axios";

export const requestDownloadSanctionLetter = (slug: string) =>
  axios.request({
    method: "get",
    url: `/refinance/api/downloadFile?path=sanction/${slug}/download`,
    responseType: "blob",
  });

export const requestMoveStage = (slug: string) =>
  axios.request({
    method: "get",
    url: `/refinance/api/springRoute?path=fees-outstanding/${slug}/move-stage`,
  });

export const requestProcessingFeeCollectionType = (slug: string) => axios.request({
  method: "get",
  url: `/refinance/api/springRoute?path=loan-component/${slug}/fee-collection-type`,
});
