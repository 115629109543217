import cloneDeep from "lodash/cloneDeep";

const getGraduationDetails = (payload: Record<string, any>) => {
  const ugDetails = cloneDeep(payload.UG);
  ugDetails.course = "UG";
  const pgDetails = payload.PG ? cloneDeep(payload.PG) : {};
  const graduationDetails = [ugDetails];
  if (payload.PG) {
    pgDetails.course = "PG";
    graduationDetails.push(pgDetails);
  }
  return graduationDetails;
};

const getTestDetails = (payload: Record<string, any>) => {
  const aptitudeArr = [];
  if (payload.aptitudeTest !== "WAIVED_OFF") {
    aptitudeArr.push({
      type: `${payload.aptitudeTest}QUANT`,
      score: payload.quant,
    });
    aptitudeArr.push({
      type: `${payload.aptitudeTest}VERBAL`,
      score: payload.verbal,
    });
  }
  return [
    ...aptitudeArr,
    {
      type: payload.englishTest,
      score: payload.englishTestScore,
    },
  ];
};

export const mutateValues = (payload: Record<string, any>) => ({
  ...payload,
  graduationDetails: getGraduationDetails(payload),
  testScores: getTestDetails(payload),
});

export const mutateInitialValues = (payload: Record<string, any>) => {
  const data = cloneDeep(payload);
  const testScores = data.profile.testScores.reduce((a: any, c: any) => {
    if (c.test === `${payload.profile.aptitudeTest}QUANT`) {
      a.quant = c.score;
    } else if (c.test === `${payload.profile.aptitudeTest}VERBAL`) {
      a.verbal = c.score;
    } else if (c.test === payload.profile.englishTest) {
      a.englishTestScore = c.score;
    }
    a.englishTest = payload.profile.englishTest;
    a.aptitudeTest = payload.profile.aptitudeTest;
    return a;
  }, {});
  const educationDetails = data.profile.educations.reduce((a: any, c: any) => {
    if (c.course === "X") {
      a.class10Percentage = c.gpa;
    } else if (c.course === "XII") {
      a.class12Percentage = c.gpa;
    } else if (c.course === "UG") {
      a.UG = { ...c };
    } else if (c.course === "PG") {
      a.PG = { ...c };
    } else {
      a.cgpaBase = c.cgpaBase;
      a.cgpa = c.cgpa;
    }
    return a;
  }, {});
  const pgDetails = data.profile.educations.filter(
    (eduData: any) => eduData.course === "PG"
  );
  if (pgDetails.length > 0) {
    educationDetails.pgIndia = "Yes";
  } else {
    educationDetails.pgIndia = "No";
  }
  const scholarshipAmount = data.scholarships.length > 0 ? data.scholarships[0].amount : null;
  return { ...testScores, ...educationDetails, hasScholarships: data.hasScholarships, scholarshipAmount };
};
