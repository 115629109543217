import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { formattedInitialValues, mutateValues } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadHelper";
import { requestApplication } from "components/ReFi/Application/redux/ApplicationApi";
import { errorMessages } from "lib/constants/constants";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  fetchEmployedProfileDocument,
  updateSubStage,
  fetchSubStage } from "components/ReFi/EmployedFlow/EmployedProfileDocument/redux/EmployedProfileDocumentSlice";
import { requestEmployedProfileDocument, requestSaveForm } from "components/ReFi/EmployedFlow/EmployedProfileDocument/redux/EmployedProfileDocumentApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = mutateValues(action.payload);
    const res = yield call(requestSaveForm, values, action.payload.subState);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmployedProfileDocument(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmployedProfileDocument, action.payload);
    if (res?.data) {
      const mutatedInitialValues = formattedInitialValues(res.data.application.profile.documents);
      yield put(updateInitialValues(mutatedInitialValues));
      yield put(updateScreen(res.data.application.refiApplicationState.stage));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchSubStage(): Generator<any, void, any> {
  try {
    const res = yield call(requestApplication);
    if (res.data.success) {
      yield put(updateSubStage(res.data?.data?.subStage));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
  }
}

function* employedProfileDocumentSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmployedProfileDocument, handleFetchEmployedProfileDocument);
  yield takeLatest(fetchSubStage, handleFetchSubStage);
}

export default employedProfileDocumentSaga;
