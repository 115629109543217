import ReactGA from "react-ga";

export const initGA = () => {
  const trackingCode: string = typeof process.env.GA_TRACKING_CODE === "string" ? process.env.GA_TRACKING_CODE : "";
  ReactGA.initialize(trackingCode);
};

export const logPageView = (path?: string) => {
  const page = path || window.location.pathname;
  ReactGA.pageview(page);
};

export const logEvent = (category: string, action: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
