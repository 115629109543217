import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import {
  fetchEmploymentStatus,
  saveForm,
  saveStudentActiveLoanStatus,
  toggleIsSaving,
  updateActiveStudentLoan,
  updateInitialValues,
  updateNextState,
  updateScreen,
} from "components/ReFi/Employment/redux/EmploymentSlice";
import {
  requestEmploymentStatus,
  requestSaveActiveLoanStatus,
  requestSaveForm,
} from "components/ReFi/Employment/redux/EmploymentApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleSaveStudentActiveLoanStatus(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveActiveLoanStatus, action.payload);
    if (res?.data?.success) {
      yield put(updateActiveStudentLoan(action?.payload?.loanStatus));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmploymentStatus(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmploymentStatus, action.payload);
    if (res?.data) {
      yield put(updateInitialValues(res?.data?.application?.profile?.employments[0] || null));
      yield put(updateActiveStudentLoan(res?.data?.application?.refiApplication?.activeStudentLoan));
      yield put(updateScreen(res?.data?.application?.refiApplicationState?.stage));
    }
  } catch (err) {
    console.error(err);
  }
}

function* employmentSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmploymentStatus, handleFetchEmploymentStatus);
  yield takeLatest(saveStudentActiveLoanStatus, handleSaveStudentActiveLoanStatus);
}

export default employmentSaga;
