import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  fetchEmployedBureauDetails } from "components/ReFi/EmployedFlow/EmployedBureauDetails/redux/EmployedBureauDetailsSlice";
import { requestEmployedBureauDetails, requestSaveForm } from "components/ReFi/EmployedFlow/EmployedBureauDetails/redux/EmployedBureauDetailsApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmployedBureauDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmployedBureauDetails, action.payload);
    if (res?.data) {
      yield put(updateInitialValues({
        ...res?.data?.application?.profile,
        indianPhone: res?.data?.application?.profile?.indianPhone?.replace("+91", "")
      }));
      yield put(updateScreen(res?.data?.application?.refiApplicationState?.stage));
    }
  } catch (err) {
    console.error(err);
  }
}

function* employedBureauDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmployedBureauDetails, handleFetchEmployedBureauDetails);
}

export default employedBureauDetailsSaga;
