import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  fetchCosignerDocumentsDetails,
  updateInitialValues,
  updateScreen
} from "components/ReFi/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSlice";
import { requestCosignerDocumentsDetails, requestSaveForm } from "components/ReFi/CosignerDocumentsUpload/redux/CosignerDocumentsUploadApi";
import { AnyAction } from "@reduxjs/toolkit";
import { formattedInitialValues, mutateValues } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadHelper";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = mutateValues(action.payload);
    const res = yield call(requestSaveForm, values);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}
function* handleFetchUserDocumentsDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestCosignerDocumentsDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(formattedInitialValues(res.data.application.cosigner1.documents)));
      yield put(updateScreen(res.data.application.applicationState.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* cosignerDocumentsUploadSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchCosignerDocumentsDetails, handleFetchUserDocumentsDetails);
}

export default cosignerDocumentsUploadSaga;
