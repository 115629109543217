import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { formattedInitialValues, mutateValues } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadHelper";
import { errorMessages } from "lib/constants/constants";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  fetchEmployedCosignerDocument } from "components/ReFi/EmployedFlow/EmployedCosignerDocument/redux/EmployedCosignerDocumentSlice";
import { requestEmployedCosignerDocument, requestSaveForm } from "components/ReFi/EmployedFlow/EmployedCosignerDocument/redux/EmployedCosignerDocumentApi";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = mutateValues(action.payload);
    const res = yield call(requestSaveForm, values);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmployedCosignerDocument(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmployedCosignerDocument, action.payload);
    if (res?.data) {
      const mutatedInitialValues = formattedInitialValues(res.data.application.cosigner1.documents);
      yield put(updateInitialValues(mutatedInitialValues));
      yield put(updateScreen(res.data.application.refiApplicationState.stage));
    }
  } catch (err) {
    console.error(err);
  }
}

function* employedCosignerDocumentSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmployedCosignerDocument, handleFetchEmployedCosignerDocument);
}

export default employedCosignerDocumentSaga;
