import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/personalDetails",
    data: values,
  });

export const requestPersonalDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile {
            firstName
            lastName
            dob
            gender
            pan
            ssn
            indianPhone
            fathersName
            mothersName
            consent
          }
          applicationState {
            screen
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "network-only"
  });
