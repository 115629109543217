import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/minimumMonthlyPayment",
    data: values,
  });

export const requestMmpDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
    query ($slug: String){
      offer (slug: $slug) {
        minimumMonthlyPayment
      }
      application(slug: $slug){
          id
          offer{
              loanInterest
              loanAmountInUSD
              loanTenure
          }
          refiApplication{
              mmpDuration
          }
          applicationState{
              screen
          }
      }
  }`,
    variables: { slug },
  });
