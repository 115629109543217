import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MmpDetailsState {
  isSaving: boolean;
  nextState: string;
  screen: string;
  initialValues: Record<string, any> | null;
  offer: {
    loanAmountInUSD: number
    loanTenure: number
    loanInterest: number
  } | null;
  mmpDuration: number;
}

const initialState = {
  isSaving: false,
  nextState: "",
  offer: null,
  mmpDuration: 0,
  screen: "",
  initialValues: null,
} as MmpDetailsState;

export const saveForm = createAction<Record<string, any>>(
  "mmpDetails/saveForm"
);

export const fetchMmpDetails = createAction<string>(
  "mmpDetails/fetchMmpDetails"
);

const mmpDetailsSlice = createSlice({
  name: "mmpDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateOffer(state, action: PayloadAction<MmpDetailsState["offer"]>) {
      const offer = action.payload;
      return { ...state, offer };
    },
    updateMmpDuration(state, action: PayloadAction<number>) {
      const mmpDuration = action.payload;
      return { ...state, mmpDuration };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
  },
});

export const { toggleIsSaving, updateNextState, updateOffer, updateMmpDuration, updateScreen, updateInitialValues } = mmpDetailsSlice.actions;
export default mmpDetailsSlice.reducer;
