import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PersonalDetailsState {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
  showExperianErrorModal: boolean;
  showExperianMismatchScreen: boolean;
  isSecondaryPhoneError: boolean;
  message: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
  college: "",
  course: "",
  showExperianErrorModal: false,
  showExperianMismatchScreen: false,
  isSecondaryPhoneError: false,
  message: "",
} as PersonalDetailsState;

export const saveForm = createAction<Record<string, any>>(
  "personalDetails/saveForm"
);

export const fetchPersonalDetails = createAction<string>(
  "personalDetails/fetchPersonalDetails"
);

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateShowExperianErrorModal(state, action: PayloadAction<boolean>) {
      const showExperianErrorModal = action.payload;
      return { ...state, showExperianErrorModal };
    },
    updateShowExperianMismatchScreen(state, action: PayloadAction<boolean>) {
      const showExperianMismatchScreen = action.payload;
      return { ...state, showExperianMismatchScreen };
    },
    updateIsSecondaryPhoneError(state, action: PayloadAction<boolean>) {
      if (state?.initialValues?.secondaryPhone) {
        const isSecondaryPhoneError = action.payload;
        return { ...state, isSecondaryPhoneError };
      }
      return { ...state };
    },
    updateMessage(state, action: PayloadAction<string>) {
      const message = action.payload;
      return { ...state, message };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  updateShowExperianErrorModal,
  updateShowExperianMismatchScreen,
  updateIsSecondaryPhoneError,
  updateMessage,
} = personalDetailsSlice.actions;
export default personalDetailsSlice.reducer;
