import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchOfferDetails,
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
} from "components/ReFi/OfferDetails/redux/OfferDetailsSlice";
import {
  requestSaveForm,
  requestOfferDetails,
} from "components/ReFi/OfferDetails/redux/OfferDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState(res?.data?.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchOfferDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestOfferDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues({ ...res?.data?.application?.offer, ...res?.data?.offer }));
      yield put(updateScreen(res?.data?.application?.applicationState?.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* offerDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchOfferDetails, handleFetchOfferDetails);
}

export default offerDetailsSaga;
