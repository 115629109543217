import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  fetchEmployedPersonalDetails } from "components/ReFi/EmployedFlow/EmployedPersonalDetails/redux/EmployedPersonalDetailsSlice";
import { requestEmployedPersonalDetails, requestSaveForm } from "components/ReFi/EmployedFlow/EmployedPersonalDetails/redux/EmployedPersonalDetailsApi";
import { mutateInitialValues } from "components/ReFi/EmployedFlow/EmployedPersonalDetails/redux/EmployedPersonalDetailsHelper";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res?.data?.success) {
      yield put(updateNextState(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmployedPersonalDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmployedPersonalDetails, action.payload);
    if (res?.data) {
      const initialValues = mutateInitialValues(res?.data?.application);
      yield put(updateInitialValues(initialValues));
      yield put(updateScreen(res?.data?.application?.refiApplicationState?.stage));
    }
  } catch (err) {
    console.error(err);
  }
}

function* employedPersonalDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmployedPersonalDetails, handleFetchEmployedPersonalDetails);
}

export default employedPersonalDetailsSaga;
