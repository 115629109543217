import axios from "axios";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: `/refinance/api/springRoute?path=refi/bank-account-details`,
    data: values
  });

export const requestBankDetails = (slug: string) =>
  axios.request({
    method: "get",
    url: `/refinance/api/springRoute?path=refi/bank-account-details/${slug}`,
  });
