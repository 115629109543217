import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import { fetchContractPendingDocuments, saveForm, toggleIsSaving, updateInitialValues, updateNextState } from "components/ReFi/EmployedFlow/ContractPending/redux/ContractPendingSlice";
import { requestSaveContractPendingForm, requestSavedDocuments } from "components/ReFi/EmployedFlow/ContractPending/redux/ContractPendingApi";
import { formattedInitialValues, mutateValues } from "components/ReFi/DocumentsUpload/redux/DocumentsUploadHelper";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = action?.payload;
    const res = yield call(requestSaveContractPendingForm, mutateValues(values));
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchContractPendingDocuments(action: AnyAction): Generator<any, void, any> {
  try {
    const slug = action?.payload;
    const res = yield call(requestSavedDocuments, slug);
    if (res?.data) {
      const mutatedInitialValues = formattedInitialValues(res.data.application.profile.documents);
      yield put(updateInitialValues(mutatedInitialValues));
    }
  } catch (err) {
    console.error(err);
  }
}

function* contractPendingSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchContractPendingDocuments, handleFetchContractPendingDocuments);
}

export default contractPendingSaga;
