import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employment",
    data: values,
  });

export const requestSaveActiveLoanStatus = (values: Record<string, any>) =>
  axios.request({
    method: "get",
    url: `/refinance/api/springRoute?path=refi/educationloan/${values?.slug}/active?status=${values?.loanStatus}`,
  });

export const requestEmploymentStatus = (slug: string) =>
  apolloClient.query({
    query: gql`
    query ($slug: String!) {
      application(slug: $slug) {
        profile {
          employments {
            id
            employmentStatus
          }
        }
        refiApplicationState {
          id
          stage
          status
          underwriting
        }
        refiApplication {
          activeStudentLoan
        }
      }
    }
    `,
    variables: { slug },
    fetchPolicy: "no-cache"
  });
