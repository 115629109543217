import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BankDetailsCollectionStateSchema {
  isSaving: boolean;
  nextState: string;
  bankDetails: any
}

const initialState: BankDetailsCollectionStateSchema = {
  isSaving: false,
  nextState: "",
  bankDetails: null,
};

export const saveForm = createAction<Record<string, any>>(
  "bankDetailsCollection/saveForm"
);

export const fetchBankDetails = createAction<string>(
  "bankDetailsCollection/fetchBankDetails"
);

const bankDetailsCollectionSlice = createSlice({
  name: "bankDetailsCollection",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateBankDetails(state, action: PayloadAction<Record<string, any>>) {
      const bankDetails = action.payload;
      return { ...state, bankDetails };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateBankDetails,
} = bankDetailsCollectionSlice.actions;
export default bankDetailsCollectionSlice.reducer;
