import cloneDeep from "lodash/cloneDeep";

export const mutateValues = (payload: Record<string, any>) => {
  const newPayload = cloneDeep(payload);
  const currency = "USD";
  const { slug } = newPayload;
  const usJobData =
    newPayload.usEmploymentStatus === "EMPLOYED" || newPayload.usEmploymentStatus === "FUTURE_EMPLOYMENT"
      ? newPayload.usJobs.reduce((a: any, c: any) => {
        a.push({
          ...c,
          currency,
          employmentStatus: c.endDate ? "PAST_EMPLOYMENT" : newPayload.usEmploymentStatus,
          country: "USA",
        });
        return a;
      }, [])
      : [{ employmentStatus: newPayload.usEmploymentStatus }];
  const indianJobData =
    newPayload.indianJobStatus === "Yes"
      ? newPayload.indianJobs.reduce((a: any, c: any) => {
        a.push({
          ...c,
          currency,
          employmentStatus: "PAST_EMPLOYMENT",
          country: "INDIA",
        });
        return a;
      }, [])
      : [];
  return { slug, jobs: [...usJobData, ...indianJobData] };
};
export const mutateInitialValues = (payload: Record<string, any>) => {
  if (payload.employments.length > 0) {
    const usJobs = payload.employments
      .filter((employment: any) => employment.employmentCountry === "USA")
      .reduce((a: any, b: any) => {
        const employment = {
          id: b.id,
          employer: b.employer,
          salary: b.salary,
          startDate: b.employmentStartDate,
          endDate: b.employmentEndDate,
          type: b.employmentType,
          workEmail: b.workEmail,
        };
        if (b.employmentEndDate) {
          a.push(employment);
        } else {
          a.unshift(employment);
        }
        return a;
      }, []);
    const indianJobs = payload.employments
      .filter((employment: any) => employment.employmentCountry === "INDIA")
      .reduce((a: any, b: any) => {
        a.push({
          id: b.id,
          employer: b.employer,
          salary: b.salary,
          startDate: b.employmentStartDate,
          endDate: b.employmentEndDate,
          workEmail: b.workEmail,
        });
        return a;
      }, []);
    if (indianJobs.length === 0) indianJobs.push({});
    const isUSJobs = payload.employments.filter(
      (employment: any) => employment.employmentCountry === "USA"
    );
    let usEmploymentStatus;
    if (isUSJobs.length > 0) {
      usEmploymentStatus = isUSJobs.find(
        (employment: any) => employment.employmentStatus === "EMPLOYED" || employment.employmentStatus === "FUTURE_EMPLOYMENT"
      ).employmentStatus;
    } else {
      usEmploymentStatus = "UNEMPLOYED";
    }
    const indianJobStatus =
    payload.employments.filter(
      (employment: any) => employment.employmentCountry === "INDIA"
    ).length > 0 ? "Yes" : "No";
    return {
      usJobs,
      indianJobs,
      usEmploymentStatus,
      indianJobStatus,
    };
  }
  return {};
};
