import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestOfferConfirmationDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
    query ($slug: String){
      offer(slug: $slug){
          id
          interestRate
          loanAmount
          loanTenure
      }
  }`,
    variables: { slug },
  });
