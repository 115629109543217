import { call, put, takeLatest } from "redux-saga/effects";
import { saveForm, toggleIsSaving, updateNextState, fetchAddressDetails, updateInitialValues, updateScreen } from "components/ReFi/AddressDetails/redux/AddressDetailsSlice";
import { requestSaveForm, requestAddressDetails } from "components/ReFi/AddressDetails/redux/AddressDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const res = yield call(requestSaveForm, action.payload);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchAddressDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestAddressDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(res.data.application));
      yield put(updateScreen(res.data.application.applicationState.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* addressDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchAddressDetails, handleFetchAddressDetails);
}

export default addressDetailsSaga;
