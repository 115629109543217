import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContractPendingStateSchema {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
}

const initialState: ContractPendingStateSchema = {
  isSaving: false,
  nextState: "",
  initialValues: null,
};

export const saveForm = createAction<Record<string, any>>(
  "contractPending/saveForm"
);

export const fetchContractPendingDocuments = createAction<string>(
  "contractPending/fetchContractPendingDocuments"
);

const contractPendingSlice = createSlice({
  name: "contractPending",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
} = contractPendingSlice.actions;
export default contractPendingSlice.reducer;
