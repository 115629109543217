import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployedOfferDetails {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
  courseName: string;
  studentName: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
  courseName: "",
  studentName: ""
} as EmployedOfferDetails;

export const saveForm = createAction<Record<string, any>>(
  "employedOfferDetails/saveForm"
);

export const fetchEmployedOfferDetails = createAction<string>(
  "employedOfferDetails/fetchEmployedOfferDetails"
);

const employedOfferDetailsSlice = createSlice({
  name: "employedOfferDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateCourseName(state, action: PayloadAction<string>) {
      const courseName = action.payload;
      return { ...state, courseName };
    },
    updateStudentName(state, action: PayloadAction<string>) {
      const studentName = action.payload;
      return { ...state, studentName };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
  updateCourseName,
  updateStudentName
} = employedOfferDetailsSlice.actions;
export default employedOfferDetailsSlice.reducer;
