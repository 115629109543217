import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployedAcademicDetails {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  screen: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  screen: "",
} as EmployedAcademicDetails;

export const saveForm = createAction<Record<string, any>>(
  "employedAcademicDetails/saveForm"
);

export const fetchEmployedAcademicDetails = createAction<string>(
  "employedAcademicDetails/fetchEmployedAcademicDetails"
);

const employedAcademicDetailsSlice = createSlice({
  name: "employedAcademicDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
} = employedAcademicDetailsSlice.actions;
export default employedAcademicDetailsSlice.reducer;
