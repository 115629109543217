import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: `/refinance/api/graphqlRoute`,
  cache: new InMemoryCache(),
});

export default apolloClient;
