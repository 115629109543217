import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employment/personalDetails",
    data: values,
  });

export const requestCoursesByTextPattern = (searchText: string) =>
  axios.request({
    method: "get",
    url: `/refinance/api/springRoute?path=course/query?pattern=${searchText}`,
  });

export const requestEmployedPersonalDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile {
            firstName
            middleName
            lastName
            email
            currentAnnualSalary
            employments {
              salary
            }
          }
          refiApplication {
            existingLoanBalance
          }
          applicationCourseDetails {
            courseName
            college {
              name
            }
            stem
          }
          refiApplicationState {
            id
            stage
            status
            underwriting
          }
          userCollegeCapture {
            id,
            course,
            campus,
            college
          }
        }
      }
    `,
    variables: { slug },
  });
