import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  fetchEmploymentDetails,
  updateInitialValues,
  updateScreen,
} from "components/ReFi/ProfessionalDetails/EmploymentDetails/redux/EmplymentDetailsSlice";
import {
  requestSaveForm,
  requestFetchEmploymentDetails,
} from "components/ReFi/ProfessionalDetails/EmploymentDetails/redux/EmploymentDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";
import { mutateValues, mutateInitialValues } from "components/ReFi/ProfessionalDetails/EmploymentDetails/redux/EmploymentDetailsHelper";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const newPayload = mutateValues(action.payload);
    const res = yield call(requestSaveForm, newPayload);
    if (res.data.success) {
      yield put(updateNextState("EDUCATION_DETAILS"));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEmploymentDetials(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestFetchEmploymentDetails, action.payload);
    if (res.data) {
      const initialValues = mutateInitialValues(res.data.application.profile);
      yield put(updateInitialValues(initialValues));
      yield put(updateScreen(res.data.application.applicationState.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* employmentDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEmploymentDetails, handleFetchEmploymentDetials);
}

export default employmentDetailsSaga;
