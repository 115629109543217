import { call, put, takeLatest } from "redux-saga/effects";
import {
  saveForm,
  toggleIsSaving,
  updateNextState,
  fetchEducationDetails,
  updateInitialValues,
  updateScreen,
} from "components/ReFi/ProfessionalDetails/EducationDetails/redux/EducationDetailsSlice";
import { requestSaveForm, requestEducationDetails } from "components/ReFi/ProfessionalDetails/EducationDetails/redux/EducationDetailsApi";
import { AnyAction } from "@reduxjs/toolkit";
import { mutateValues, mutateInitialValues } from "components/ReFi/ProfessionalDetails/EducationDetails/redux/EducationDetailsHelper";

function* handleSaveForm(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const values = mutateValues(action.payload);
    const res = yield call(requestSaveForm, values);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchEducationDetails(action: AnyAction): Generator<any, void, any> {
  try {
    const res = yield call(requestEducationDetails, action.payload);
    if (res.data) {
      const initialValues = mutateInitialValues(res.data.application);
      yield put(updateInitialValues(initialValues));
      yield put(updateScreen(res.data.application.applicationState.screen));
    }
  } catch (err) {
    console.error(err);
  }
}

function* educationDetailsSaga() {
  yield takeLatest(saveForm, handleSaveForm);
  yield takeLatest(fetchEducationDetails, handleFetchEducationDetails);
}

export default educationDetailsSaga;
