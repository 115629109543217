import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PFCollectionTypes } from "DataMapper/Refi/constants";

interface SanctionGeneratedStateSchema {
  isSaving: boolean;
  nextState: string;
  screen: string;
  isDownloading: boolean;
  isSanctionLetterDownloaded: boolean;
  pfCollectionTypeApplicable: PFCollectionTypes;
}

const initialState: SanctionGeneratedStateSchema = {
  isSaving: false,
  nextState: "",
  screen: "",
  isDownloading: false,
  isSanctionLetterDownloaded: false,
  pfCollectionTypeApplicable: PFCollectionTypes.COLLECT_PF_LOAN_FEE,
};

export const moveStage = createAction<string>(
  "sanctionGenerated/moveStage"
);

export const downloadSanctionLetter = createAction<string>(
  "sanctionGenerated/downloadSanctionLetter"
);

export const fetchPFFeeCollectionType = createAction<string>(
  "sanctionGenerated/fetchPFFeeCollectionType"
);

const sanctionGeneratedSlice = createSlice({
  name: "sanctionGenerated",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    toggleIsDownloading(state, action: PayloadAction<boolean>) {
      const isDownloading = action.payload;
      return { ...state, isDownloading };
    },
    updateIsSanctionLetterDownloaded(state, action: PayloadAction<boolean>) {
      const isSanctionLetterDownloaded = action.payload;
      return { ...state, isSanctionLetterDownloaded };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updatePFCollectionTypeApplicable(state, action: PayloadAction<PFCollectionTypes>) {
      const pfCollectionTypeApplicable = action.payload;
      return { ...state, pfCollectionTypeApplicable };
    }
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateScreen,
  toggleIsDownloading,
  updateIsSanctionLetterDownloaded,
  updatePFCollectionTypeApplicable
} = sanctionGeneratedSlice.actions;
export default sanctionGeneratedSlice.reducer;
