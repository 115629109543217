import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import { downloadSanctionLetter, fetchPFFeeCollectionType, moveStage, toggleIsDownloading, toggleIsSaving, updateIsSanctionLetterDownloaded, updateNextState, updatePFCollectionTypeApplicable } from "components/ReFi/EmployedFlow/SanctionGenerated/redux/SanctionGeneratedSlice";
import { requestDownloadSanctionLetter, requestMoveStage, requestProcessingFeeCollectionType } from "components/ReFi/EmployedFlow/SanctionGenerated/redux/SanctionGeneratedApi";
import { saveAs } from "file-saver";

function* handleMoveStage(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const slug = action?.payload;
    const res = yield call(requestMoveStage, slug);
    if (res.data.success) {
      yield put(updateNextState(res.data.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleFetchPFFeeCollectionType(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsSaving(true));
    const slug = action?.payload;
    const res = yield call(requestProcessingFeeCollectionType, slug);
    if (res.data.success) {
      yield put(updatePFCollectionTypeApplicable(res.data.data));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsSaving(false));
  }
}

function* handleDownloadSanctionLetter(action: AnyAction): Generator<any, void, any> {
  try {
    yield put(toggleIsDownloading(true));
    const slug = action?.payload;
    const res = yield call(requestDownloadSanctionLetter, slug);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers["content-disposition"].split(
        "filename="
      )[1];
      saveAs(res.data, fileName);
      yield put(updateIsSanctionLetterDownloaded(true));
    } else {
      toast.error(errorMessages.DOWNLOAD_FAILED);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(toggleIsDownloading(false));
  }
}

function* sanctionGeneratedSaga() {
  yield takeLatest(moveStage, handleMoveStage);
  yield takeLatest(downloadSanctionLetter, handleDownloadSanctionLetter);
  yield takeLatest(fetchPFFeeCollectionType, handleFetchPFFeeCollectionType);
}

export default sanctionGeneratedSaga;
