import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmployedBureauDetails {
  isSaving: boolean;
  nextState: {
    stage: string;
    status: string;
  } | null;
  initialValues: Record<string, any> | null;
  screen: string;
}

const initialState = {
  isSaving: false,
  nextState: null,
  initialValues: null,
  screen: "",
} as EmployedBureauDetails;

export const saveForm = createAction<Record<string, any>>(
  "employedBureauDetails/saveForm"
);

export const fetchEmployedBureauDetails = createAction<string>(
  "employedBureauDetails/fetchEmployedBureauDetails"
);

const employedBureauDetailsSlice = createSlice({
  name: "employedBureauDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<EmployedBureauDetails["nextState"]>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
  },
});

export const {
  toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateScreen,
} = employedBureauDetailsSlice.actions;
export default employedBureauDetailsSlice.reducer;
