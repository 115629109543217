export const cosignerStatus = {
  COSIGNER_REJECTED: "COSIGNER_REJECTED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
};

export const loanFeedBulletPoints = [
  {
    title: "Fastest Sanction",
    description: "Get your loan in 3-5 days",
  },
  {
    title: "Collateral free",
    description: "No property documents required",
  },
  {
    title: "Build Credit Score in USA",
    description: "Your loan repayments will build your credit score in USA. This will help you in future.",
  },
];

export const errorMessages = {
  SOMETHING_WENT_WRONG: "Something went wrong!",
  NOT_AUTHORIZED: "You are not authorized to view this page.",
  FAILED_TO_FETCH: "Failed to fetch data from server, Please try again later.",
  DOWNLOAD_FAILED: "Download failed. Contact support if problem persists.",
};
