import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { errorMessages } from "lib/constants/constants";
import {
  fetchEmployedOfferConfirmationDetails,
  updateLoanOfferDetails } from "components/ReFi/EmployedFlow/EmployedOfferConfirmation/redux/EmployedOfferConfirmationSlice";
import { requestEmployedOfferConfirmationDetails } from "components/ReFi/EmployedFlow/EmployedOfferConfirmation/redux/EmployedOfferConfirmationApi";

function* handleFetchEmployedOfferConfirmationDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestEmployedOfferConfirmationDetails, action.payload);
    if (res?.data) {
      yield put(updateLoanOfferDetails(res.data.application.offer));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* employedOfferConfirmationDetailsSaga() {
  yield takeLatest(fetchEmployedOfferConfirmationDetails, handleFetchEmployedOfferConfirmationDetails);
}

export default employedOfferConfirmationDetailsSaga;
