import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/uploadCosignerDocuments",
    data: values,
  });

export const requestCosignerDocumentsDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
    query ($slug: String){
      application(slug: $slug){
          id
          profile{
           documents{
               name
               keyName
               size
               url
           }
          }
          cosigner1{
              documents{
               name
               keyName
               size
               url
           }
          }
          cosigner2{
              documents{
               name
               keyName
               size
               url
           }
          }
          applicationState{
              screen
          }
      }
  }`,
    variables: { slug },
  });
