import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CosignerDetailsState {
  isSaving: boolean;
  nextState: string;
  initialValues: Record<string, any> | null;
  status: string;
  showExperianErrorModal: boolean;
  showExperianMismatchScreen: boolean;
  isSecondaryPhoneError: boolean;
  message: string;
  screen: string;
  cosignerAttempt: number;
  cosigner1Pan: string;
}

const initialState = {
  isSaving: false,
  nextState: "",
  initialValues: null,
  status: "",
  showExperianErrorModal: false,
  showExperianMismatchScreen: false,
  isSecondaryPhoneError: false,
  message: "",
  cosignerAttempt: 0,
  cosigner1Pan: "",
  screen: "",
} as CosignerDetailsState;

export const saveForm = createAction<Record<string, any>>(
  "cosignerDetails/saveForm"
);

export const fetchCosignerDetails = createAction<string>(
  "cosignerDetails/fetchCosignerDetails"
);

const cosignerDetailsSlice = createSlice({
  name: "cosignerDetails",
  initialState,
  reducers: {
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateInitialValues(state, action: PayloadAction<Record<string, any> | null>) {
      const initialValues = action.payload;
      return { ...state, initialValues };
    },
    updateStatus(state, action: PayloadAction<string>) {
      const status = action.payload;
      return { ...state, status };
    },
    updateShowExperianErrorModal(state, action: PayloadAction<boolean>) {
      const showExperianErrorModal = action.payload;
      return { ...state, showExperianErrorModal };
    },
    updateShowExperianMismatchScreen(state, action: PayloadAction<boolean>) {
      const showExperianMismatchScreen = action.payload;
      return { ...state, showExperianMismatchScreen };
    },
    updateIsSecondaryPhoneError(state, action: PayloadAction<boolean>) {
      if (state?.initialValues?.secondaryPhone) {
        const isSecondaryPhoneError = action.payload;
        return { ...state, isSecondaryPhoneError };
      }
      return { ...state };
    },
    updateMessage(state, action: PayloadAction<string>) {
      const message = action.payload;
      return { ...state, message };
    },
    updateScreen(state, action: PayloadAction<string>) {
      const screen = action.payload;
      return { ...state, screen };
    },
    updateCosignerAttempt(state, action: PayloadAction<number>) {
      const cosignerAttempt = action.payload;
      return { ...state, cosignerAttempt };
    },
    updateCosigner1Pan(state, action: PayloadAction<string>) {
      const cosigner1Pan = action.payload;
      return { ...state, cosigner1Pan };
    },
  },
});

export const { toggleIsSaving,
  updateNextState,
  updateInitialValues,
  updateStatus,
  updateShowExperianErrorModal,
  updateShowExperianMismatchScreen,
  updateIsSecondaryPhoneError,
  updateMessage,
  updateScreen,
  updateCosignerAttempt,
  updateCosigner1Pan,
} =
  cosignerDetailsSlice.actions;
export default cosignerDetailsSlice.reducer;
