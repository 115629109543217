import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employment/bureauDetails",
    data: values,
  });

export const requestEmployedBureauDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          profile {
            pan
            indianPhone
            consent
          }
          refiApplicationState {
            id
            stage
            status
            underwriting
          }
        }
      }
    `,
    variables: { slug },
  });
