import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

interface CourseDetailsState {
  colleges: Array<any>;
  isSaving: boolean;
  nextState: string;
  campusOptions: Array<any>;
  courseOptions: Array<any>;
  collegeName: string;
  courseName: string;
  notEligibleReason: string;
  applicationId: number | null;
}

const initialState = {
  colleges: [],
  campusOptions: [],
  courseOptions: [],
  isSaving: false,
  nextState: "",
  collegeName: "",
  courseName: "",
  isEmailValidated: false,
  notEligibleReason: "",
  applicationId: null,
} as CourseDetailsState;

export const saveLead = createAction<Record<string, any>>(
  "courseDetails/saveLead"
);

export const saveForm = createAction<Record<string, any>>(
  "courseDetails/saveForm"
);

export const fetchCollege = createAction<string>("courseDetails/fetchCollege");

const courseDetailsSlice = createSlice({
  name: "courseDetails",
  initialState,
  reducers: {
    getColleges() {},
    setColleges(state, action: PayloadAction<AxiosResponse>) {
      const colleges = action.payload.data;
      return { ...state, colleges };
    },
    setCampus(state, action: PayloadAction<Array<any>>) {
      const campusOptions = action.payload;
      return { ...state, campusOptions };
    },
    setCourse(state, action: PayloadAction<Array<any>>) {
      const courseOptions = action.payload;
      return { ...state, courseOptions };
    },
    toggleIsSaving(state, action: PayloadAction<boolean>) {
      const isSaving = action.payload;
      return { ...state, isSaving };
    },
    updateNextState(state, action: PayloadAction<string>) {
      const nextState = action.payload;
      return { ...state, nextState };
    },
    updateCollegeName(state, action: PayloadAction<string>) {
      const collegeName = action.payload;
      return { ...state, collegeName };
    },
    updateCampusName(state, action: PayloadAction<string>) {
      const campusName = action.payload;
      return { ...state, campusName };
    },
    updateCourseName(state, action: PayloadAction<string>) {
      const courseName = action.payload;
      return { ...state, courseName };
    },
    updateIsEmailValidated(state, action: PayloadAction<string>) {
      const isEmailValidated = action.payload;
      return { ...state, isEmailValidated };
    },
    updateNotEligibleReason(state, action: PayloadAction<string>) {
      const notEligibleReason = action.payload;
      return { ...state, notEligibleReason };
    },
    updateApplicationId(state, action: PayloadAction<number>) {
      const applicationId = action.payload;
      return { ...state, applicationId };
    },
  },
});

export const {
  getColleges,
  setColleges,
  setCampus,
  setCourse,
  toggleIsSaving,
  updateNextState,
  updateCollegeName,
  updateCampusName,
  updateCourseName,
  updateIsEmailValidated,
  updateNotEligibleReason,
  updateApplicationId,
} = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
