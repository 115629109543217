import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/employment/offerDetails",
    data: values,
  });

export const requestEmployedOfferDetails = (slug: string) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
          }
          applicationCourseDetails {
            courseName
            college {
              name
            }
            stem
          }
          refiApplicationState {
            id
            stage
            status
            underwriting
          }
          profile {
            firstName
          }
        }
      }
    `,
    variables: { slug },
  });
