import axios from "axios";
import apolloClient from "apollo";
import { gql } from "@apollo/client";

export const requestSaveForm = (values: Record<string, any>) =>
  axios.request({
    method: "post",
    url: "/refinance/api/springRoute?path=refi/cosignerDetails",
    data: values,
  });

export const requestCosignerDetails = async (slug: string) => {
  await apolloClient.resetStore();
  return apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          cosignerAttempt
          cosigner1 {
            id
            firstName
            middleName
            lastName
            relationship
            phone
            isPhoneVerified
            consent
            email
            dob
            gender
            fathersName
            dependents
            employmentStatus
            totalWorkExperience
            employer
            monthlyNetTakeHome
            monthlyObligation
            companyVintage
            company
            pat
            typeOfResidence
            residentialProof
            pan
            creditScore
            secondaryEmail
            secondaryPhone
            employerAddress {
              line1
              line2
              city
              state
              pincode
            }
            currentAddress {
              line1
              line2
              city
              state
              pincode
            }
            experianStatus
          }
          cosigner2 {
            id
            firstName
            middleName
            lastName
            relationship
            phone
            isPhoneVerified
            consent
            email
            dob
            gender
            fathersName
            dependents
            employmentStatus
            totalWorkExperience
            employer
            monthlyNetTakeHome
            monthlyObligation
            companyVintage
            company
            pat
            typeOfResidence
            residentialProof
            pan
            creditScore
            secondaryEmail
            secondaryPhone
            employerAddress {
              line1
              line2
              city
              state
              pincode
            }
            currentAddress {
              line1
              line2
              city
              state
              pincode
            }
            experianStatus
          }
          applicationState {
            screen
            underwriting
          }
        }
      }
    `,
    variables: { slug },
  });
};
