import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ApplicationState {
  slug: string;
  noApplication: boolean | null;
  application: {
    slug: string
    stage: string
    subStage: string
    underwriting: string
  };
  activeTab: string;
  loading: boolean;
  offerDetails: {
    label: string
    value: string
  }[];
}

const initialState = {
  slug: "",
  noApplication: null,
  application: {},
  activeTab: "Refi",
  loading: true,
} as ApplicationState;

export const fetchApplication = createAction(
  "application/fetchApplication"
);

export const createApplication = createAction<string | null | undefined>("application/createApplication");
export const fetchOfferDetails = createAction<string>("application/fetchOfferDetails");

const aplicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateSlug(state, action: PayloadAction<string>) {
      const slug = action.payload;
      return { ...state, slug };
    },
    updateNoApplication(state, action: PayloadAction<boolean>) {
      const noApplication = action.payload;
      return { ...state, noApplication };
    },
    updateApplication(state, action: PayloadAction<ApplicationState["application"]>) {
      const application = action.payload;
      return { ...state, application };
    },
    updateActiveTab(state, action: PayloadAction<string>) {
      const activeTab = action.payload;
      return { ...state, activeTab };
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      const loading = action.payload;
      return { ...state, loading };
    },
    updateOfferDetails(state, action: PayloadAction<ApplicationState["offerDetails"]>) {
      return { ...state, offerDetails: action?.payload };
    }
  },
});

export const { updateSlug, updateNoApplication, updateApplication, updateActiveTab, updateLoading, updateOfferDetails } = aplicationSlice.actions;
export default aplicationSlice.reducer;
