import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { requestOfferConfirmationDetails } from "components/ReFi/FinalLoanOffer/redux/OfferConfirmationApi";
import { fetchOfferConfirmationDetails, updateOffer } from "components/ReFi/FinalLoanOffer/redux/OfferConfirmationSlice";

function* handleFetchOfferConfirmationDetails(
  action: AnyAction
): Generator<any, void, any> {
  try {
    const res = yield call(requestOfferConfirmationDetails, action.payload);
    if (res.data) {
      yield put(updateOffer(res.data.offer));
    }
  } catch (err) {
    console.error(err);
  }
}

function* offerConfirmationDetailsSaga() {
  yield takeLatest(fetchOfferConfirmationDetails, handleFetchOfferConfirmationDetails);
}

export default offerConfirmationDetailsSaga;
