/* eslint-disable import/no-extraneous-dependencies */
import "styles/globals.css";
import type { AppProps, AppContext } from "next/app";
import { wrapper } from "redux/store";
import App from "next/app";
import { NextPageContext } from "next";
import { saveUtmData } from "utils/utm_utils";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initGA } from "components/Analytics/google-analytics";
import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";

const theme = createTheme();

const isClientSide = typeof window !== "undefined";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    // enabled: process.env.NODE_ENV === "production",
    enabled: true,
    dsn: process.env.SENTRY_DSN,
    tracesSampleRate: 0.25,
    autoSessionTracking: true,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: isClientSide ? [
      new CaptureConsole({ levels: ["error"] }),
      new Sentry.Replay(),
    ] : [
      new CaptureConsole({ levels: ["error"] }),
    ],
    ignoreErrors: [
      // Specify the error patterns or types to ignore
      "analytics.js",
      /(analytics\.js|Analytics\.js)/,
      "segment",
      /segment|Segment/
    ],
  });
}

const MyApp = ({ Component, ...rest }: AppProps) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;
  useEffect(() => {
    initGA();
    TagManager.initialize({
      gtmId: process.env.GTM_ID || "",
    });
    if (process.env.HOTJAR_SITEID) {
      hotjar.initialize(+process.env.HOTJAR_SITEID, 6);
    }
  }, []);
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

const captureAndSaveUtm = (context: NextPageContext, query: any) => {
  const utmSource = query && query.utm_source;
  const utmCampaign = query && query.utm_campaign;
  const utmTerm = query && query.utm_term;
  const campaignType = query && query.campaign_type;
  const adId = query && query.ad_id;
  const trackingId = query && query.tracking_id;
  const utmMedium = query && query.utm_medium;

  saveUtmData(context, {
    utmSource: utmSource as string,
    utmCampaign: utmCampaign as string,
    utmTerm: utmTerm as string,
    utmMedium: utmMedium as string,
    adId: adId as string,
    campaignType: campaignType as string,
    trackingId: trackingId as string,
  });
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  captureAndSaveUtm(appContext.ctx, appContext.ctx.query);
  const appProps = await App.getInitialProps(appContext);
  return appProps;
};

export default MyApp;
